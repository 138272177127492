import React from 'react';
import {AppComponent} from '../app_components';
import bindAll from 'lodash/bindAll';
import sortBy from 'lodash/sortBy';
import ProductInstallation from '../models/product_installation.js';

import strftime from 'strftime/strftime.js';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { attemptParseInt, andJoin } from '../util.js';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import Select from '@material-ui/core/Select';

class PickupsView extends AppComponent {
  constructor(props) {
    super(props);

    bindAll(this, ['clickRow', 'renderPickupRow']);
  }

  clickNewPickup() {
    App.router.goNewPickup();
  }

  clickRow(p) {
    App.router.goPickup(p.id);
  }

  renderPickupRow(p) {
    let date = strftime('%-m/%-d/%y ~ %l:%M %p', p.created_at);

    let workers = andJoin(p.workers.map(w => w.username));
    let buildings = andJoin(p.buildings.map(b => b.name));

    return (
      <div key={'prow_' + p.id} onClick={() => { this.clickRow(p) }}
        className='pickup-row list-group-item d-flex justify-content-between align-items-center p-1'>
        <div>
          {workers} loaded {p.total()} products on a truck on {date} at {buildings}
        </div>
        <ArrowForwardIcon />
      </div>
    )
  }

  render() {
    let pickups = window.pickups;
    return (
      <div className='p-1 mt-2'>
        <div className='d-flex align-items-center justify-content-between'>
          <h5>Pickups ({pickups.length})</h5>
          <button onClick={this.clickNewPickup} type="button" className="btn btn-outline-dark">New Pickup</button>
        </div>
        <div id='pickups' className='mt-3'>
          {pickups.map(this.renderPickupRow)}
        </div>
      </div>
    );
  }
}

export default PickupsView;

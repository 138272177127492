// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
// require("channels")

import {} from '../react_helpers.js';

import AppRouter from '../app_router.js';
import Filter from '../models/filter.js';

import {handleData} from '../app_specific_utils.js';
import {getAllStats} from '../stats_helper.js';

window.handleData = handleData;
window.App = {};
window.Filter = Filter;

App.colors = {
  secondary: '#6c757d',
  danger: '#dc3545',
  warning: '#ffc107',
  info: '#17a2b8'
};

App.router = new AppRouter();

window.getAllStats = getAllStats;

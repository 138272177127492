import ApplicationObject from './application_object.js';
import compact from 'lodash/compact';
import pickBy from 'lodash/pickBy';
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';
import { postData, patchData } from '../fetch_helpers.js';
import { assignFriendlyDate, findById, areSameDay } from '../util.js';

const main_attrs = ['removed', 'uninstalled', 'set_missing'];
let main_attr_names = {};
main_attrs.forEach((m) => {
  let at = `${m}_at`;
  let ts = `${at}_ts`;
  let by = `${m}_by`;
  let id = `${by}_id`;

  main_attr_names[m] = {
    at: at,
    ts: ts,
    by: by,
    id: id,
    friendly: `friendly_${at}`
  }
});
const update_attrs = ['removed_by', 'uninstalled_by', 'set_missing_by'];

const status_map = {
  0: 'installed',
  1: 'uninstalled',
  2: 'removed',
  3: 'missing'
};

const colorsByName = {
  installed: 'danger',
  uninstalled: 'warning',
  removed: 'info'
}

const colorsByNum = {
  0: 'danger',
  1: 'warning',
  2: 'info'
}

const collapsed_short_order = ['BL', 'MF', 'SR', 'BS', 'ES', 'TV', 'FT'];
const short_order = ['BL', 'MF', 'SR', 'BS', 'ES', 'TV32', 'TV40', 'DFT', 'FT'];

const stats_attrs = ['uninstalled', 'removed'];

var working = false;

class ProductInstallation extends ApplicationObject {
  assignData() {
    this.student = findById(window.students, this.customer_id);
    this.product = findById(window.products, this.product_id);
    let room = findById(window.rooms, this.room_id);

    this.room = room;
    let building = findById(window.buildings, room.building_id);

    this.building = building;

    this.school = findById(window.schools, building.school_id);

    main_attrs.forEach((attr) => {
      let id = main_attr_names[attr].id;
      let by = main_attr_names[attr].by;

      if (this[id]) {
        this[by] = findById(window.workers, this[id]);
      }
    });

    if (this.uninstalled_by_id) {
      this.uninstalled_by = findById(window.workers, this.uninstalled_by_id);
    }

  }

  getActivityData() {
    var at, ts, by, id, txt, friendly;

    return compact(main_attrs.map((attr) => {
      ({at, ts, by, id, friendly} = ProductInstallation.getActivityAttrsWithNames()[attr]);
      if (!this[id]) return null;
      let short = this.product.short;

      txt = `${short} ${by.replace(/_/g, ' ')} ${this[by].username} on ${this[friendly]}`;

      return {
        install_id: this.id,
        attr_name: attr,
        at: this[at],
        ts: this[ts],
        by: this[by],
        id: this[id],
        txt: txt
      }
    }));
  }

  getStatusName(){
    return status_map[this.status];
  }

  static getNameFromNumber(num) {
    return status_map[num];
  }

  static getColorFromStatusName(name) {
    return colorsByName[name];
  }

  static getActivityAttrs() {
    return main_attrs;
  }

  static getActivityAttrsWithNames() {
    return main_attr_names;
  }

  assignProdDataAfterUpdate(obj) {
    this.status = obj.status_int;

    main_attrs.forEach((attr) => {
      let id = main_attr_names[attr].id;
      let by = main_attr_names[attr].by;
      let ts = main_attr_names[attr].ts;
      let at = main_attr_names[attr].at;

      if (obj[id]) {
        assignFriendlyDate(this, ts, obj[ts]);
        this[id] = obj[id];
        this[by] = workers.find(w => w.id == obj[id]);
      } else {
        this[`friendly_${at}`] = null;
        this[id] = null;
        this[by] = null;
        this[ts] = null;
        this[at] = null;
      }
    });

    this.room.assignInstallData();

    App.roomView.forceUpdate();

    setTimeout(() => {
      App.mainView.forceUpdate();
    }, 200)
  }

  letter() {
    return this.purchase ? 'p' : null;
  }

  hasBeenTouchedBy(user) {
    return !!update_attrs.find(a => this[`${a}_id`] === user.id);
  }

  touchesBy(user) {
    return pickBy({
      1: this.uninstalled_by_id === user.id,
      2: this.removed_by_id === user.id
    }, (val, key) => val);
  }

  touchesByUserOnDate(user, date) {
    let uninstalled_date = this.uninstalled_at;
    let removed_date = this.removed_at;

    return pickBy({
      1: this.uninstalled_by_id === user.id && areSameDay(uninstalled_date, date),
      2: this.removed_by_id === user.id && areSameDay(removed_date, date)
    }, (val, key) => val);
  }

  hasTouchInDate(user, date) {
    var day, month, tdate, tmonth, tday;

    day = date.getDate();
    month = date.getMonth();

    return !!stats_attrs.find((attr) => {
      if (this[`${attr}_by_id`] !== user.id) return null;

      date = this[`${attr}_at`];
      tday = date.getDate();
      tmonth = date.getMonth();

      if (day === tday && month === tmonth) {
        return true;
      }
    });
  }


  activityForUser(user) {
    let activity = [];

    if (this.uninstalled_by_id === user.id) {
      activity.push({
        type: 'uninstall',
        install: this,
        date: this.uninstalled_at,
        ts: this.uninstalled_at_ts
      });
    }

    if (this.removed_by_id === user.id) {
      activity.push({
        type: 'removal',
        install: this,
        date: this.removed_at,
        ts: this.removed_at_ts
      });
    }

    return activity;
  }

  activityForUserAndDate(user, date) {
    let activity = [];
    var day,month,tday,tmonth,within_date;

    day = date.getDate();
    month = date.getMonth();

    if (this.uninstalled_by_id === user.id) {
      [tday, tmonth] = [this.uninstalled_at.getDate(), this.uninstalled_at.getMonth()];

      within_date = day === tday && month === tmonth;

      if (within_date) {
        activity.push({
          type: 'uninstall',
          install: this,
          date: this.uninstalled_at,
          ts: this.uninstalled_at_ts
        });
      }
    }

    if (this.removed_by_id === user.id) {
      [tday, tmonth] = [this.removed_at.getDate(), this.removed_at.getMonth()];

      within_date = day === tday && month === tmonth;

      if (within_date) {
        activity.push({
          type: 'removal',
          install: this,
          date: this.removed_at,
          ts: this.removed_at_ts
        });
      }
    }

    return activity;
  }

  static getCollapsedShorts() {
    return collapsed_short_order;
  }

  static getShorts() {
    return short_order;
  }

  isBuilding() {
    return false;
  }

  static sortByShort(installs) {
    return sortBy(installs, i => short_order.indexOf(i.product.short));
  }

  static sortCollapsedShort(installs) {
    var short;
    installs = sortBy(installs, (i) => {
      short = i.short;

      if (/FT$/.test(short)) {
        short = 'FT';
      } else if (/^TV/.test(short)) {
        short = 'TV';
      }

      short_order.indexOf(short)
    });

    return installs;
  }

  static update(install, attrs, cb) {
    if (working) return;
    working = true;

    return patchData(`/product_installations/${install.id}`, {install: attrs})
      .catch(error => alert('an error occurred'))
      .then((json) => {
        if (json) {
          if (cb) cb(json);
        }

        working = false
      })
    ;
  }

}

export default ProductInstallation;

import { createBrowserHistory } from 'history';
import { isDefined, findById } from './util.js';
import bindAll from 'lodash/bindAll';

const history = createBrowserHistory();

class AppRouter {
  constructor() {
    bindAll(this, ['listener']);
  }

  start() {
    const unlisten = history.listen(this.listener);
  }

  listener(location, action) {
    let s = location.state || App.starterState;

    if (action === 'POP') {
      if (s.viewShown === 'List') {
        App.mainListView.forceUpdate();
      }

      App.mainView.setState(s);
    }
  }

  goCheatSheet() {
    let state = {viewShown: 'CheatSheet'};
    App.mainView.setState(state);
    history.push('/cheatsheet', state);
  }

  goWorkers() {
    let state = {viewShown: 'Workers'};
    App.mainView.setState(state);
    history.push('/workers', state);
  }

  goFilterStats() {
    let state = {viewShown: 'FilterStats'};
    App.mainView.setState(state);
    history.push('/filter_stats', state);
  }

  goYourFilters() {
    let state = {viewShown: 'YourFilters'};
    App.mainView.setState(state);
    history.push('/your_filters', state);
  }

  goYourWork() {
    let state = {viewShown: 'YourWork'};
    App.mainView.setState(state);
    history.push('/your_work', state);
  }

  goBuilding(building_id) {
    let state = {viewShown: 'Building', building_id: building_id};
    App.mainView.setState(state);
    history.push(`/buildings/${building_id}`, state);
  }

  goFilter() {
    let state = {viewShown: 'Filter'};
    App.mainView.setState(state);
    history.push('/filter', state);
  }

  goHome() {
    let state = {viewShown: 'Home'};
    App.mainView.setState(state);
    history.push('/', state);
  }

  goList() {
    setTimeout(() => {
      let state = {viewShown: 'List'};
      App.mainView.setState(state);
      App.mainListView.forceUpdate();
      history.push('/jobs', state);
    }, 20);
  }

  filterList(school_id) {
    App.mainListView.setSchoolFilter(parseInt(school_id));
  }

  goRoom(room_id) {
    setTimeout(() => {
      let room = findById(window.rooms, room_id);
      App.mainView.setRoom(room_id);

      history.push(`/rooms/${room_id}`, {room_id: room_id, viewShown: 'Room'});
    }, 20);
  }

  goNewPickup() {
    let state = {viewShown: 'PickupForm', pickup_id: null};
    App.mainView.setState(state);
    history.push('/pickups/new', state);
  }

  goNewStack() {
    let state = {viewShown: 'StackForm', stack_id: null};
    App.mainView.setState(state);
    history.push('/stacks/new', state);
  }

  goPickups() {
    let state = {viewShown: 'Pickups'};
    App.mainView.setState(state);
    history.push('/pickups', state);
  }

  goPickup(pickup_id) {
    setTimeout(() => {
      let state = {pickup_id: pickup_id, viewShown: 'ShowPickup'};

      App.mainView.setState(state);

      history.push(`/pickups/${pickup_id}`, state);
    }, 20);
  }

  editPickup(pickup_id) {
    let state = {pickup_id: pickup_id, viewShown: 'PickupForm'};

    let pickup = findById(window.pickups, pickup_id);

    if (current_user.admin || pickup.creator_id === current_user.id) {
      App.mainView.setState(state);

      history.push(`/pickups/${pickup_id}/edit`, state);
    }
  }

  goStack(stack_id) {
    setTimeout(() => {
      let state = {stack_id: stack_id, viewShown: 'ShowStack'};

      App.mainView.setState(state);

      history.push(`/stacks/${stack_id}`, state);
    }, 20);
  }

  goStacks() {
    let state = {viewShown: 'Stacks'};
    App.mainView.setState(state);
    history.push('/stacks', state);
  }

  editStack(stack_id) {
    let state = {stack_id: stack_id, viewShown: 'StackForm'};

    let stack = findById(window.stacks, stack_id);

    if (current_user.admin || stack.creator_id === current_user.id) {
      App.mainView.setState(state);

      history.push(`/stacks/${stack_id}/edit`, state);
    }
  }

  goWorker(worker) {
    let state = {worker_id: worker.id, viewShown: 'ShowWorker'};

    App.mainView.setState(state);

    history.push(`/users/${worker.id}`, state);
  }


}

export default AppRouter;

import ApplicationObject from './application_object.js';
import {postData, patchData} from '../fetch_helpers.js';
import {findById} from '../util.js';

var working = false;

class FieldNote extends ApplicationObject {
  constructor(attrs) {
    super(attrs);
  }

  static create(room_id, attrs, cb) {
    if (working) return;

    working = true;

    let params = {room_id, field_note: attrs};

    postData('/field_notes', params)
     .catch(error => alert('an error occurred'))
     .then((json) => {
       if (json) {
         let note = new FieldNote(json);
         window.field_notes.push(note);

         note.assignData();
         note.room.assignData();

         App.roomView.forceUpdate();

         if (cb) cb()
       }

       working = false;
     });
  }

  static update(note, attrs, cb) {
    if (working) return;

    working = true;

    let params = {
      pickup: attrs
    };

    patchData(`/field_notes/${note.id}`, params)
     .catch(error => alert('an error occurred'))
     .then((json) => {
       if (json) {
         note.resetData(json);
         note.assignData();
         App.roomView.forceUpdate();

         if (cb) cb();
       }

       working = false;
     });
  }

  assignData() {
    this.room = findById(window.rooms, this.room_id);
    this.user = findById(window.workers, this.user_id);
  }
}

export default FieldNote;

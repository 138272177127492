import React from 'react';
import pick from 'lodash/pick';
import merge from 'lodash/merge';
import flatten from 'lodash/flatten';
import pickBy from 'lodash/pickBy';
import concat from 'lodash/concat';
import { presence } from '../util.js';
import bindAll from 'lodash/bindAll';
import ProductInstallation from '../models/product_installation.js';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import classnames from 'classnames';
import FormGroup from '@material-ui/core/FormGroup';
import Divider from '@material-ui/core/Divider';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
  },
  secondary: {
    color: App.colors.secondary,
    '&$checked': {
      color: App.colors.secondary,
    },
  },
  danger: {
    color: App.colors.danger,
    '&$checked': {
      color: App.colors.danger,
    },
  },
  warning: {
    color: App.colors.warning,
    '&$checked': {
      color: App.colors.warning,
    },
  },
  info: {
    color: App.colors.info,
    '&$checked': {
      color: App.colors.info,
    },
  },
  checked: {}
});

const main_attrs = ProductInstallation.getActivityAttrs();
const user_attrs = flatten(main_attrs.map(a => [`${a}_by_id`, `friendly_${a}_at`]));

const all_attrs = concat(user_attrs, ['id', 'status']);

class ProductActionModal extends React.PureComponent {
  constructor(props) {
    super(props);

    bindAll(this, ['clickSave', 'close', 'open']);

    this.state = {
      open: false
    };
  }

  handleChange = name => event => {
    var val;

    val = event.target.checked ? current_user.id : null;

    this.setState({ [name]: val });
  };

  clickSave() {
    let attrs = pickBy(this.state, (val,key) => {
      return /_id$/.test(key);
    });

    ProductInstallation.update(this.install, attrs, (json) => {
      let install = this.install;
      install.assignProdDataAfterUpdate(json);

      let state = merge(pick(install, all_attrs), {open: false});
      this.setState(state);
    });
  }

  open(install) {
    this.install = install;
    let state = pick(install, all_attrs);
    state.product_short = install.product.short;
    state.open = true;
    this.setState(state);
  };

  close() {
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;

    const klass = classnames({
      'text-danger': this.state.status === 0 ,
      'text-warning': this.state.status === 1,
      'text-info': this.state.status === 2
    })

    var uninstalled_text;
    var uninstalled_enabled = true;
    if (this.state.uninstalled_by_id) {
      let worker = workers.find(w => w.id == this.state.uninstalled_by_id);
      let uat = presence(this.state.friendly_uninstalled_at) || null;
      uninstalled_text = `Uninstalled by ${worker.username}${uat ? ` on ${uat}` : ''}`;
      uninstalled_enabled = current_user.admin || worker.id == current_user.id;
    } else {
      uninstalled_text = "Uninstalled";
    }

    var removed_text;
    var removed_enabled = true;
    if (this.state.removed_by_id) {
      let worker = workers.find(w => w.id == this.state.removed_by_id);
      let rat = presence(this.state.friendly_removed_at) || null;
      removed_text = `Removed by ${worker.username}${rat ? ` on ${rat}` : ''}`;
      removed_enabled = current_user.admin || worker.id == current_user.id;
    } else {
      removed_text = "Removed";
    }

    var set_missing_text;
    var set_missing_enabled = true;
    if (this.state.set_missing_by_id) {
      let worker = workers.find(w => w.id == this.state.set_missing_by_id);
      let sat = presence(this.state.friendly_set_missing_at) || null;
      set_missing_text = `Set missing by ${worker.username}${sat ? ` on ${sat}` : ''}`;
      set_missing_enabled = current_user.admin || worker.id == current_user.id;
    } else {
      set_missing_text = "Set product as missing";
    }

    return (
      <div>
        <Modal
          open={this.state.open}
          onClose={this.close}
        >
          <div style={getModalStyle()} className={classes.paper}>
            <Typography variant="h6" id="modal-title">
              Select the actions you did for
              <span className={klass}> {this.state.product_short}</span>?
            </Typography>

            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!this.state.uninstalled_by_id}
                    onChange={this.handleChange('uninstalled_by_id')}
                    disabled={!uninstalled_enabled}
                    classes={{
                      root: classes.danger,
                      checked: classes.checked,
                    }}
                  />
                }
                label={uninstalled_text}
              />
           </FormGroup>

           <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!this.state.removed_by_id}
                    onChange={this.handleChange('removed_by_id')}
                    disabled={!removed_enabled}
                    classes={{
                      root: classes.warning,
                      checked: classes.checked,
                    }}
                  />
                }
                label={removed_text}
              />
           </FormGroup>

            <Typography variant="h6" id="modal-title">
              OR
            </Typography>

            <FormGroup row>
             <FormControlLabel
              control={
                <Checkbox
                  checked={!!this.state.set_missing_by_id}
                  onChange={this.handleChange('set_missing_by_id')}
                  disabled={!set_missing_enabled}
                  classes={{ root: classes.secondary, checked: classes.checked }}
                />
              }
                label={set_missing_text}
             />
           </FormGroup>

           <Divider className='mt-1 mb-3' />

           <div className='d-flex justify-content-between'>
             <Button variant="outlined" onClick={this.close}>Cancel</Button>
             <Button variant="outlined" onClick={this.clickSave}>Save</Button>
           </div>

           <ProductActionModalWrapped />
          </div>
        </Modal>
      </div>
    );
  }
}

// We need an intermediary variable for handling the recursive nesting.
const ProductActionModalWrapped = withStyles(styles)(ProductActionModal);

export default ProductActionModalWrapped;

import {getData} from './fetch_helpers.js';

export const refreshData = (cb) => {
  getData('/data').then((json) => {
    let users = json.users;

    window.workers = users.filter(u => !u.student);
    window.installs = json.installs;
    window.products = json.products;
    window.rooms = json.rooms;
    window.buildings = json.buildings;
    window.schools = json.schools;
    window.payscales = json.payscales;
    window.students = users.filter(u => u.student);
    window.tickets = json.tickets;
    window.pickups = json.pickups;
    window.stacks = json.stacks;

    handleData(current_user.id);

    App.mainListView.forceUpdate();
    App.roomView.forceUpdate();

    if (App.currentView) App.currentView.forceUpdate();

    if (cb) cb()
  });
}

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import { FormGroup, Label, Input } from 'reactstrap';
import { isPresent } from '../util.js';
import bindAll from 'lodash/bindAll';
import FieldNote from '../models/field_note.js';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
  },
});

class FieldNoteModal extends React.PureComponent {
  handleClose() {
    this.setState({ open: false });
  };

  constructor(props) {
    super(props);

    bindAll(this, ['clickSubmit', 'handleClose', 'open', 'changedNoteText']);

    this.state = {
      open: false,
    };
  }

  open(note) {
    this.note = note;

    setTimeout(() => {
      this.setState({
        open: true,
        note: note ? note.note : ''
      });
    }, 100);
  };

  clickSubmit(e) {
    let data = {
      note: this.state.note
    };

    if (this.note) {
      FieldNote.update(this.note, () => {this.handleClose()});
    } else {
      FieldNote.create(App.room.id, data, () => {this.handleClose()});
    }
  }

  changedNoteText(e) {
    this.setState({
      note: e.currentTarget.value
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Modal
          open={this.state.open}
          onClose={this.handleClose}
        >
          <div style={getModalStyle()} className={classes.paper}>
            <p>Write a field note:</p>

            <FormGroup>
              <Input value={this.state.needs_attention_text} style={{minHeight: 90}} onChange={this.changedNoteText} type="textarea" name="text" id="note_text" />
            </FormGroup>

            <div className='d-flex justify-content-between'>
              <Button variant="outlined" onClick={this.handleClose}>Cancel</Button>
              <Button variant="outlined" onClick={this.clickSubmit}>Submit</Button>
            </div>

            <FieldNoteModalWrapped />
          </div>
        </Modal>
      </div>
    );
  }
}

// We need an intermediary variable for handling the recursive nesting.
const FieldNoteModalWrapped = withStyles(styles)(FieldNoteModal);

export default FieldNoteModalWrapped;

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import { FormGroup, Label, Input } from 'reactstrap';
import { isPresent } from '../util.js';
import bindAll from 'lodash/bindAll';
import Ticket from '../models/ticket.js';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
  },
});

class NeedsAttentionModal extends React.PureComponent {
  handleClose() {
    this.setState({ open: false });
  };

  constructor(props) {
    super(props);

    bindAll(this, ['clickSubmit', 'handleClose', 'open', 'changed_needs_attention_text']);

    this.state = {
      open: false,
    };
  }

  open(needs_attention_text) {
    setTimeout(() => {
      this.setState({
        open: true,
        needs_attention_text: needs_attention_text || ''
      });
    }, 100);
  };

  clickSubmit(e) {
    let data = {
      text: this.state.needs_attention_text
    };

    Ticket.create(App.room.id, data, () => {this.handleClose()});
  }

  changed_needs_attention_text(e) {
    this.setState({
      needs_attention_text: e.currentTarget.value
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Modal
          open={this.state.open}
          onClose={this.handleClose}
        >
          <div style={getModalStyle()} className={classes.paper}>
            <p>Please tell us why this room needs attention</p>

            <FormGroup>
              <Input value={this.state.needs_attention_text} style={{minHeight: 90}} onChange={this.changed_needs_attention_text} type="textarea" name="text" id="na_text" />
            </FormGroup>

            <div className='d-flex justify-content-between'>
              <Button variant="outlined" onClick={this.handleClose}>Cancel</Button>
              <Button variant="outlined" onClick={this.clickSubmit}>Submit</Button>
            </div>

            <NeedsAttentionModalWrapped />
          </div>
        </Modal>
      </div>
    );
  }
}

// We need an intermediary variable for handling the recursive nesting.
const NeedsAttentionModalWrapped = withStyles(styles)(NeedsAttentionModal);

export default NeedsAttentionModalWrapped;

import React from 'react';
import Button from '@material-ui/core/Button';
import bindAll from 'lodash/bindAll';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';

class YourFiltersView extends React.Component {
  constructor(props) {
    super(props);

    bindAll(this, ['renderFilter', 'clickToggleApply', 'clickDelete']);
  }

  clickToggleApply(filter) {
    filter.toggleApply();

    if (filter.applied) {
      App.mainListView.prepareListForFilter(filter);
      App.router.goList();
    } else {
      this.forceUpdate();
    }
  }

  clickDelete(filter) {
    Filter.deleteFilter(filter);

    this.forceUpdate();
  }

  renderFilter(filter) {
    return (
      <div key={`filter_${filter.id}`} className='d-flex justify-content-between mt-4'>
        <div>{filter.getFilterText()}</div>
        <div className='d-flex flex-column'>
          <Button variant='outlined'
            onClick={() => this.clickToggleApply(filter)}
            color={filter.applied ? 'default' : 'primary'}>
            {filter.applied ? 'Unapply' : 'Apply'}
          </Button>
          <Button onClick={() => this.clickDelete(filter)} className='mt-1'
            variant='outlined' color='secondary'>
            Delete
          </Button>
        </div>
      </div>
    )
  }

  render() {
    let filters = sortBy(App.filters, f => f.id);

    return (
      <div className='p-2'>
        <h3>Your Filters</h3>

        {filters.map(this.renderFilter)}
      </div>
    )
  }
}

export default YourFiltersView;

import React from 'react';
import strftime from 'strftime/strftime.js';

export const simpleActivityText = function(activity, txt) {
  if (!activity) return null;

  let time = strftime('%l:%M %p', activity.date);

  let type = activity.type === 'pickup' ? 'truck load' : activity.type;

  return (
    <div>
      <span className='fw-500 mr-2'>{txt} activity:</span>
      {type} @ {time}
    </div>
  )
};

import ApplicationObject from './application_object.js';

import sum from 'lodash/sum';
import pick from 'lodash/pick';
import flatten from 'lodash/flatten';
import concat from 'lodash/concat';
import sortBy from 'lodash/sortBy';
import groupBy from 'lodash/groupBy';

import {areSameDay, millisecondsToHours} from '../util.js';

import Pickup from './pickup.js';
import Stack from './stack.js';

const cols = ['id', 'username', 'first_name', 'last_name', 'email',
'room_id', 'phone_number', 'role', 'admin', 'worker', 'student'];

class User extends ApplicationObject {
  constructor(attrs) {
    super(attrs)

    this.admin = attrs.role === 0;
    this.worker = attrs.role === 1;
  }

  fullName() {
    return this.first_name + ' ' + this.last_name;
  }

  toJSON() {
    return pick(this, cols);
  }

  getDaysWorked() {
    return Object.keys(this.getGroupedActivity());
  }

  getGroupedActivity() {
    let activity = this.getAllActivity();

    return groupBy(activity, (a) => {
      return `${a.date.getMonth() + 1}/${a.date.getDate()}`
    });
  }

  getTotalHoursWorked() {
    let activity = this.getGroupedActivity()

    var a, first, last, hours, has_wm, has_ur;
    let all_hours = Object.keys(activity).map((day) => {
      a = activity[day];
      first = a[0]
      last = a[a.length - 1]

      if (!last) return 0;

      hours = millisecondsToHours(last.date - first.date) + 1

      let [has_wm, has_ur] = Pickup.hasWmOrUrDrive(this, first.date);

      if (has_wm) hours += 2;
      if (has_ur) hours += 1;

      return hours;
    });

    return sum(all_hours).toFixed(1);
  }

  getAllDeliveries() {
    return window.pickups.filter(p => p.creator_id === this.id);
  }

  getAllDeliveriesForDate(date) {
    return this.getAllDeliveries().filter((p) => {
      return areSameDay(p.created_at, date);
    });
  }

  getAllActivity() {
    let activity = [];
    let today = new Date()
    var install, short;
    let installs = window.installs;

    var i;
    for (i = 0; i < installs.length; i++) {
      install = installs[i];
      short = install.product.short;
      let a = install.activityForUser(this);
      if (a && a.length) activity.push(a);
    }

    let pickups = Pickup.pickupsForUser(this);

    pickups = pickups.map(p => (
      {
        type: 'pickup',
        pickup: p,
        date: p.created_at,
        ts: p.created_at_ts
      }
    ))

   let stacks = Stack.stacksForUser(this);

    stacks = stacks.map(s => (
      {
        type: 'stack',
        stack: s,
        date: s.created_at,
        ts: s.created_at_ts
      }
    ))

    activity = flatten(concat(activity, pickups, stacks));

    return sortBy(activity, a => a.ts);
  }

  getAllActivityForUserOnDate(date) {
    let activity = [];
    var install, short;
    let installs = window.installs;

    var i;
    for (i = 0; i < installs.length; i++) {
      install = installs[i];
      short = install.product.short;
      let a = install.activityForUserAndDate(this, date);
      if (a && a.length) activity.push(a);
    }

    let pickups = Pickup.pickupsForUserAndDate(this, date);

    pickups = pickups.map(p => (
      {
        type: 'pickup',
        pickup: p,
        date: p.created_at,
        ts: p.created_at_ts
      }
    ))

    let stacks = Stack.stacksForUserAndDate(this, date);

    stacks = stacks.map(s => (
      {
        type: 'stack',
        stack: s,
        date: s.created_at,
        ts: s.created_at_ts
      }
    ))

    activity = flatten(concat(activity, pickups, stacks));

    return sortBy(activity, a => a.ts);
  }
}

export default User;

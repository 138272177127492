import React from 'react';

import {centsToDollarString, shorterCentsToDollarString} from '../util.js';

import ProductInstallation from '../models/product_installation.js';

var key1 = 1;
export default class PayscalesTable extends React.Component {
  constructor(props) {
    super(props);
  }

  renderPayscaleTd(short, ps) {
    let k = 'payscale_' + (key1 += 1);
    let name = short.toLowerCase() + '_cents';
    let str = centsToDollarString(ps[name]);

    return (
      <td key={k}>{str}</td>
    )
  }

  render() {
    let payscales = window.payscales;
    let uninstall_payscale = payscales.find(p => p.install_type === 0);
    let removal_payscale = payscales.find(p => p.install_type === 1);

    const all_shorts = ProductInstallation.getCollapsedShorts();

    return (
      <div>
        <div>
          <span className='text-warning'>uninstall prices</span>
          <span className='ml-3 text-info'>removal prices</span>
        </div>
        <table className='muhtable'>
          <thead>
            <tr>
              {all_shorts.map(s => (<th key={key1+=1}>{s}</th>))}
            </tr>
          </thead>
          <tbody>
            <tr className='uninstalled'>
              {all_shorts.map(s => { return this.renderPayscaleTd(s, uninstall_payscale) })}
            </tr>
            <tr className='removed'>
              {all_shorts.map(s => { return this.renderPayscaleTd(s, removal_payscale) })}
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}

import React from 'react';
import classnames from 'classnames';
import bindAll from 'lodash/bindAll';
import get from 'lodash/get';
import isString from 'lodash/isString';

import {findById} from '../util.js';

import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import MainAppBar from './main_app_bar.jsx';
import MainDrawer from './main_drawer.jsx';
import YourFiltersView from './your_filters_view.jsx';

import WorkersView from './workers_view.jsx';
import HomeView from './home_view.jsx';
import RoomView from './room_view.jsx';
import CheatSheetView from './cheat_sheet_view.jsx';
import BuildingView from './building_view.jsx';
import FilterView from './filter_view.jsx';
import YourWorkView from './your_work_view.jsx';
import FilterStatsView from './filter_stats_view.jsx';

import PickupsView from './pickups_view.jsx';
import PickupForm from './pickup_form.jsx';
import ShowPickup from './show_pickup_view.jsx';

import StacksView from './stacks_view.jsx';
import StackForm from './stack_form.jsx';
import ShowStack from './show_stack_view.jsx';

import MainList from './main_list.jsx';

class Main extends React.PureComponent {
  drawerRef = React.createRef();

  constructor(props) {
    super(props);

    bindAll(this, ['clickMenu', 'render', 'setRoom']);

    this.state = {
      viewShown: props.viewShown || 'List',
      filter_school_id: null,
      room_id: props.room_id || null,
      building_id: props.building_id || null,
      pickup_id: props.pickup_id || null,
      worker_id: props.worker_id || null,
      stack_id: props.stack_id || null
    }

    App.mainView = this;
  }

  clickMenu() {
    let vs = this.state.viewShown;
    let go_list = vs === 'Room' || vs === 'FilterStats' || vs === 'Building';

    if (go_list) {
      App.router.goList();
    } else if (vs === 'PickupForm') {
      if (this.state.pickup_id) {
        App.router.goPickup(this.state.pickup_id);
      } else {
        App.router.goPickups();
      }
    } else if (vs === 'ShowPickup') {
      App.router.goPickups();
    } else if (vs === 'StackForm') {
      if (this.state.stack_id) {
        App.router.goStack(this.state.stack_id);
      } else {
        App.router.goStacks();
      }
    } else if (vs === 'ShowStack') {
      App.router.goStacks();
    } else if (vs === 'ShowWorker') {
      App.router.goWorkers();
    } else {
      this.drawerRef.current.open();
    }
  }

  setState(attrs) {
    attrs.room_id = isString(attrs.room_id) ? parseInt(attrs.room_id) : attrs.room_id;

    super.setState(attrs);
  }

  setRoom(room_id) {
    this.setState({
      room_id: room_id,
      viewShown: 'Room'
    })
  }

  render() {
    let view = this.state.viewShown;

    var title;

    return (
      <div>
        <MainDrawer innerRef={this.drawerRef} toggleDrawer={this.toggleDrawer} />
        <MainAppBar roomId={this.state.room_id} buildingId={this.state.building_id}
          workerId={this.state.worker_id}
          clickMenu={this.clickMenu} viewShown={this.state.viewShown} />

        <div className={view === 'editProfile' ? 'hidden' : ''}>
          <div className={view == 'List' ? '' : 'hidden'}>
            <MainList />
          </div>

          <div className={view === 'Room' ? '' : 'hidden'}>
            <RoomView roomId={this.state.room_id}/>
          </div>

          {view === 'Home' ? (<HomeView />) : null}
          {view === 'Building' ? (<BuildingView buildingId={this.state.building_id} />) : null }
          {view === 'Filter' ? (<FilterView />) : null }
          {view === 'YourFilters' ? (<YourFiltersView />) : null }
          {view === 'FilterStats' ? (<FilterStatsView />) : null }
          {view === 'YourWork' ? (<YourWorkView />) : null }
          {view === 'ShowWorker' ? (<YourWorkView workerId={this.state.worker_id}/>) : null }
          {view === 'CheatSheet' ? (<CheatSheetView />) : null }
          {view === 'Pickups' ? (<PickupsView />) : null }
          {view === 'PickupForm' ? (<PickupForm pickupId={this.state.pickup_id} />) : null }
          {view === 'ShowPickup' ? (<ShowPickup pickupId={this.state.pickup_id} />) : null }
          {view === 'Workers' ? (<WorkersView />) : null }
          {view === 'Stacks' ? (<StacksView />) : null }
          {view === 'StackForm' ? (<StackForm stackId={this.state.stack_id} />) : null }
          {view === 'ShowStack' ? (<ShowStack stackId={this.state.stack_id} />) : null }
        </div>
      </div>
    )
  }
}

// https://stackoverflow.com/a/44754061/548170
export default Main;
// export default Main);

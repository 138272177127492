import React from 'react';
import bindAll from 'lodash/bindAll';
import Stack from '../models/stack.js';

import toLower from 'lodash/toLower';

import { attemptParseInt, findById } from '../util.js';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import Select from '@material-ui/core/Select';

var key1=99;

class StackForm extends React.PureComponent {
  constructor(props) {
    super(props);

    bindAll(this, ['clickSubmit', 'renderProd', 'handleChangeNum', 'handleMultiSelectChange', 'changeText']);

    if (props.stackId) {
      this.stack = findById(window.stacks, props.stackId);
      this.state = this.stack.toJSON();
    } else {
      this.state = {
        building_ids: [],
        worker_ids: [],
        text: '',
        bl_count: 0
      }
    }
  }

  handleChangeNum(e) {
    let num = parseInt(e.target.value);
    if (!num) {
      num = '';
    } else if (num < 0) {
      num = 0;
    }
    let name = toLower(e.target.getAttribute('name')) + '_count';
    this.setState({[name]: num});
  }

  renderProd(s) {
    let val = this.state[toLower(s) + '_count'];

    return (
      <div key={`td_${s}`} className='d-flex mt-1'>
        <span style={{minWidth: 40}} className='fw-500'>{s}:</span>
        <input onChange={this.handleChangeNum} value={val} name={s} type='number' className='form-control' style={{width:100}} />
      </div>
    )
  }

  handleMultiSelectChange(event) {
    const { options } = event.target;
    const values = [];
    const name = event.target.id.split('-')[0];

    var id;

    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        values.push(attemptParseInt(options[i].value));
      }
    }

    let obj = { [name]: values };

    this.setState(obj);
  };

  changeText(e) {
    this.setState({text: e.target.value});
  }

  clickSubmit() {
    if (!this.state.worker_ids.length) {
      alert('Please select some workers');
      return false;
    }

    if (this.stack) {
      Stack.update(this.stack, this.state);
    } else {
      Stack.create(this.state);
    }
  }

  render() {
    let workers = this.state.worker_ids.map(id => findById(window.workers, id));

    return (
      <div className='p-1 mt-2'>
        {this.stack &&
          <div className='my-2 mb-4'>
            Editing stack created by {this.stack.username} on {this.stack.friendly_created_at}
          </div>
        }

        <div className='d-flex justify-content-between'>
          <FormControl className='mb-2'>
            <InputLabel shrink htmlFor="worker_ids-filter">
              Select Workers
            </InputLabel>

            <Select inputProps={{id: 'worker_ids-filter'}}
              multiple native
              value={this.state.worker_ids}
              onChange={this.handleMultiSelectChange}
            >
              {window.workers.map(w => (
                <option key={`worker_option_${w.id}`} value={w.id}>
                  {w.username}
                </option>
              ))}
            </Select>
          </FormControl>
        </div>


        {workers.length > 0 &&
          <div>
            <span className='fw-500 mr-2'>Workers:</span>
            { workers.map(w => w.username).join(', ') }
          </div>
        }

        <div key={`td_nada`} className='d-flex mt-1 align-items-center'>
          <span style={{minWidth: 40}} className='fw-500'>BL count:</span>
          <input onChange={this.handleChangeNum} value={this.state.bl_count} name={'bl'} type='number' className='form-control' style={{width:100}} />
        </div>

       <div className="form-group mt-2">
         <label htmlFor="textarea">Notes</label>
         <textarea value={this.state.text} className="form-control" id="textarea" rows="3" onChange={this.changeText}>
         </textarea>
       </div>

       <div className='mt-1'>
         <button type="button" className="btn btn-primary" onClick={this.clickSubmit}>Submit</button>
       </div>
      </div>
    );
  }
}

export default StackForm;

import React from 'react';
import {AppComponent} from '../app_components';

import bindAll from 'lodash/bindAll';
import sortBy from 'lodash/sortBy';

import PhoneIcon from '@material-ui/icons/Phone';
import Link from '@material-ui/core/Link';

export default class WorkersView extends AppComponent {
  constructor(props) {
    super(props);

    bindAll(this, ['renderWorker', 'clickRow']);
  }

  clickRow(w) {
    if (current_user.admin) {
      App.router.goWorker(w);
    }
  }

  renderWorker(w) {
    let k = 'workuh_' + w.id;

    return (
      <div key={k} style={{height: 50}} onClick={() => {this.clickRow(w)} }
      className='pl-2 d-flex align-items-center justify-content-between list-group-item'>
        <div className='d-flex'>
          {w.fullName()} -
          <div className='ml-1'>{w.username}</div>
        </div>

        <Link href={`tel:${w.phone_number}`} className='mx-3'>
          <PhoneIcon />
        </Link>
      </div>
    )
  }

  render() {
    let workers = sortBy(window.workers, w => w.first);

    return (
      <div>
        {workers.map(this.renderWorker)}
      </div>
    )
  }
}

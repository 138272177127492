import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import bindAll from 'lodash/bindAll';
import Button from '@material-ui/core/Button';
import DatePicker from './date_picker.jsx';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import strftime from 'strftime/strftime.js';
import Room from '../models/room.js';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
  },
});

class RoomUnoccupiedAtModal extends React.PureComponent {
  constructor(props) {
    super(props);

    bindAll(this, ['handleClose', 'clickCancel', 'clickIDontKnow', 'clickSubmit', 'changedDate', 'changedTime']);

    this.state = {
      dateValue: '',
      open: false
    };
  }

  open(date) {
    if (!date) {
      date = new Date();
      date.setMinutes(date.getMinutes() + 30);
    }

    let dateValue = strftime('%Y-%m-%d', date);
    let timeValue = strftime('%H:%M', date);

    setTimeout(() => {
      this.setState({
        dateValue: dateValue,
        timeValue: timeValue,
        open: true
      });
    }, 20);
  };

  handleClose() {
    this.setState({ open: false });
  };

  clickCancel() {
    this.handleClose();
  }

  clickIDontKnow() {
    let params = {occupied: true, date_input_value: ''};

    Room.update(App.room.id, params, this.handleClose);
  }

  clickSubmit() {
    let params = {
      occupied: true,
      date_input_value: this.state.dateValue,
      time_input_value: this.state.timeValue
    };

    Room.update(App.room.id, params, this.handleClose);
  }

  changedDate(e) {
    this.setState({
      dateValue: e.target.value
    });
  }

  changedTime(e) {
    this.setState({
      timeValue: e.target.value
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Modal
          open={this.state.open}
          onClose={this.handleClose}
        >
          <div style={getModalStyle()} className={classes.paper}>
            <Typography variant='h6'>When will this room be unoccupied?</Typography>

            <div className='d-flex my-4 justify-content-between'>
              <TextField
                id="date"
                label="Date"
                onChange={this.changedDate}
                defaultValue={this.state.dateValue}
                type="date"
                InputLabelProps={{ shrink: true }}
              />

              <TextField
                id="time"
                label="Time"
                type="time"
                onChange={this.changedTime}
                defaultValue={this.state.timeValue}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
              />
            </div>

            <Divider className='m-2' />

            <div className='d-flex justify-content-between align-items-center'>
              <Button onClick={this.clickCancel} variant="outlined">Cancel</Button>
              <Button onClick={this.clickIDontKnow} variant="outlined">I don't know</Button>
              <Button onClick={this.clickSubmit} variant="outlined">Submit</Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

// We need an intermediary variable for handling the recursive nesting.
const RoomUnoccupiedAtModalWrapped = withStyles(styles)(RoomUnoccupiedAtModal);

export default RoomUnoccupiedAtModalWrapped;

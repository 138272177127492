import React from 'react';
import bindAll from 'lodash/bindAll';
import sortBy from 'lodash/sortBy';
import lowerCase from 'lodash/lowerCase';

import ProductInstallation from '../models/product_installation.js';
import Pickup from '../models/pickup.js';
import Filter from '../models/filter.js';

import { attemptParseInt, findById } from '../util.js';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import Select from '@material-ui/core/Select';

var key1=99;

class PickupForm extends React.PureComponent {
  constructor(props) {
    super(props);

    bindAll(this, ['clickSubmit', 'renderProd', 'handleChangeNum', 'handleMultiSelectChange', 'changeText', 'handleSchoolChange']);

    let shorts = ProductInstallation.getCollapsedShorts();

    if (props.pickupId) {
      this.pickup = findById(window.pickups, props.pickupId);
      this.state = this.pickup.toJSON();
    } else {
      let f = Filter.getCurrentFilter();

      let school_short = (f && f.schools.length === 1 ? f.schools[0].short : '');

      this.state = {
        building_ids: [],
        worker_ids: [],
        school_short: school_short,
        text: ''
      }

      shorts.forEach(s => this.state[lowerCase(s) + '_count'] = 0);
    }
  }

  handleChangeNum(e) {
    let num = parseInt(e.target.value);
    if (!num) {
      num = '';
    } else if (num < 0) {
      num = 0;
    }
    let name = lowerCase(e.target.getAttribute('name')) + '_count';
    this.setState({[name]: num});
  }

  renderProd(s) {
    let val = this.state[lowerCase(s) + '_count'];

    return (
      <div key={`td_${s}`} className='d-flex mt-1'>
        <span style={{minWidth: 40}} className='fw-500'>{s}:</span>
        <input onChange={this.handleChangeNum} value={val} name={s} type='number' className='form-control' style={{width:100}} />
      </div>
    )
  }

  handleMultiSelectChange(event) {
    const { options } = event.target;
    const values = [];
    const name = event.target.id.split('-')[0];

    var id;

    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        values.push(attemptParseInt(options[i].value));
      }
    }

    let obj = { [name]: values };

    this.setState(obj);
  };

  changeText(e) {
    this.setState({text: e.target.value});
  }

  clickSubmit() {
    if (!this.state.worker_ids.length) {
      alert('please select some workers');
      return;
    }

    if (!this.state.building_ids.length) {
      alert('please select some buildings');
      return;
    }

    if (this.pickup) {
      Pickup.update(this.pickup, this.state);
    } else {
      let school = window.schools.find(s => s.short === this.state.school_short);
      Pickup.create(school.id, this.state);
    }
  }

  handleSchoolChange(e) {
    let short = e.target.value;

    this.setState({
      school_short: short,
      building_ids: []
    });
  }

  render() {
    var school, select_buildings;

    if (this.state.school_short) {
      school = window.schools.find(s => s.short === this.state.school_short);
      select_buildings = sortBy(window.buildings.filter(b => b.school_id == school.id), b => b.name);
    }

    let workers = this.state.worker_ids.map(id => findById(window.workers, id));
    let buildings = this.state.building_ids.map(id => findById(window.buildings, id));

    let shorts = ProductInstallation.getCollapsedShorts();

    return (
      <div className='p-1 mt-2'>
        {this.pickup &&
          <div className='my-2 mb-4'>
            Editing pickup created by {this.pickup.username} on {this.pickup.friendly_created_at}
          </div>
        }

        <div className='d-flex justify-content-between'>
          <FormControl style={{width: 200}} >
            <InputLabel htmlFor="school-select">Select School</InputLabel>
            <Select
              native
              value={this.state.school_short}
              onChange={this.handleSchoolChange}
              inputProps={{ id: 'school-select' }}
            >
              <option value="" />
              <option value="richmond">Richmond</option>
              <option value="wm">William & Mary</option>
            </Select>
          </FormControl>

          {this.state.school_short &&
            <FormControl className='mb-2'>
              <InputLabel shrink htmlFor="building_ids-filter">
                Select Buildings
              </InputLabel>

              <Select inputProps={{id: 'building_ids-filter'}}
                multiple native
                value={this.state.building_ids}
                onChange={this.handleMultiSelectChange}
              >
                {select_buildings.map(b => (
                  <option key={`building_option_${b.id}`} value={b.id}>
                    {b.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          }
        </div>

        <FormControl className='my-4'>
          <InputLabel shrink htmlFor="worker_ids-filter">
            Select Workers
          </InputLabel>

          <Select inputProps={{id: 'worker_ids-filter'}}
            multiple native
            value={this.state.worker_ids}
            onChange={this.handleMultiSelectChange}
          >
            {window.workers.map(w => (
              <option key={`worker_option_${w.id}`} value={w.id}>
                {w.username}
              </option>
            ))}
          </Select>
        </FormControl>

        {buildings.length > 0 &&
          <div>
            <span className='fw-500 mr-2'>Buildings:</span>
            { buildings.map(b => b.name).join(', ') }
          </div>
        }

        {workers.length > 0 &&
          <div>
            <span className='fw-500 mr-2'>Workers:</span>
            { workers.map(w => w.username).join(', ') }
          </div>
        }

        <div>
          {shorts.map(this.renderProd)}
        </div>

       <div className="form-group mt-2">
         <label htmlFor="textarea">Notes</label>
         <textarea value={this.state.text} className="form-control" id="textarea" rows="3" onChange={this.changeText}>
         </textarea>
       </div>

       <div className='mt-1'>
         <button type="button" className="btn btn-primary" onClick={this.clickSubmit}>Submit</button>
       </div>
      </div>
    );
  }
}

export default PickupForm;

import React from 'react';
import bindAll from 'lodash/bindAll';
import isNull from 'lodash/isNull';
import flatten from 'lodash/flatten';
import sortBy from 'lodash/sortBy';
import Button from '@material-ui/core/Button';
import strftime from 'strftime/strftime.js';

import Link from '@material-ui/core/Link';

import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import WarningIcon from '@material-ui/icons/Warning';

import Room from '../models/room.js';
import Ticket from '../models/ticket.js';
import ProductInstallation from '../models/product_installation.js';
import NeedsAttentionModal from './needs_attention_modal.jsx';
import FieldNoteModal from './field_note_modal.jsx';
import ProductActionModal from './product_action_modal.jsx';
import RoomUnoccupiedAtModal from './room_unoccupied_at_modal.jsx';

import classnames from 'classnames';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';

const main_attrs = ProductInstallation.getActivityAttrs();

var key9 = 1;

class RoomView extends React.PureComponent {
  modalRef = React.createRef();
  roomOccupiedModalRef = React.createRef();
  productActionModalRef = React.createRef();
  fieldNoteModalRef = React.createRef();

  constructor(props) {
    super(props);
    App.roomView = this;
    this.state = {};
    bindAll(this, ['render', 'toggleLocked', 'toggleNeedsAttention', 'clickProduct', 'toggleFieldNoteModal', 'renderNote',
      'renderInstallButton', 'renderStudentRow', 'markResolved', 'renderTicketTr', 'clickOccupied', 'clickChangeUnoccupiedDate', 'handleLockedChange'])
  }

  toggleLocked(e) {
    Room.update(this.room.id, {locked: !this.room.locked});
  }

  clickOccupied() {
    if (this.room.occupied) {
      Room.update(this.room.id, {occupied: false});
    } else {
      this.roomOccupiedModalRef.current.open();
    }
  }

  toggleFieldNoteModal(e) {
    this.fieldNoteModalRef.current.open();
  }

  toggleNeedsAttention(e) {
    this.modalRef.current.open();
  }

  clickProduct(e, i) {
    this.productActionModalRef.current.open(i);
  }

  renderInstallButton(i, index) {
    let installed = i.status === 0;
    let uninstalled = i.status === 1;
    let removed = i.status === 2;

    let klass = i.purchase ? 'mr-3 purchase' : (classnames('mr-3', {
      'border-danger text-danger': installed,
      'border-warning text-warning': uninstalled,
      'border-info text-info': removed
    }));

    let key = `button_${i.product.short}_${index + 1}`;
    return (
      <Button className={klass} key={key}
        onClick={e => { this.clickProduct(e,i) }} variant="outlined">
        {i.product.short}
      </Button>
    )
  }

  renderAction(datum) {
    let key = `somebs_${key9+=1}}`;
    let klass = 'd-flex mt-1 align-items-center justify-content-between mr-1';
    return (
      <div key={key} style={{fontSize: 14}} className={klass}>
        <div>{datum.txt}</div>
      </div>
    )
  }

  renderStudentRow(student) {
    let installs = this.room.rented_installs.filter(i => i.customer_id === student.id)
    installs = ProductInstallation.sortByShort(installs);

    let key = `student_row_${student.id}`;
    return (
      <div key={key} className='mt-3'>
        <div className='text-secondary d-flex justify-content-between'>
          <div>products for {student.fullName()}:</div>

          <div>
            <Link href={`tel:${student.phone_number}`} className='mx-3'>
              <PhoneIcon />
            </Link>
            <Link href={`mailto:${student.email}`} className='mx-3'>
              <EmailIcon />
            </Link>
          </div>
        </div>

        <div className='d-flex mt-3'>
          {installs.map(i => this.renderInstallButton(i))}
        </div>
      </div>
    )
  }

  markResolved(t) {
    if (confirm("Did you resolve this issue?")) {
      Ticket.markResolved(this.room.id, t.id);
    }
  }

  markUnresolved(t) {
    Ticket.markUnresolved(this.room.id, t.id);
  }

  renderTicketTr(t) {
    return (
      <tr key={`ticket_tr_${t.id}`}>
        <td>{t.text}</td>
        <td>{t.creator.username}</td>

        <td style={{textAlign: 'right'}}>
          {!t.resolved_by &&
            <Button size='small' onClick={() => {this.markResolved(t)}} variant="outlined">
              Resolve
            </Button>
          }

          {t.resolved_by &&
            <span style={{fontSize: 12}}>Resolved by {t.resolved_by.username}</span>
          }
        </td>
      </tr>
    )
  }

  renderTickets() {
    let tickets = this.room.tickets;

    if (tickets.length === 0) return null;

    return (
      <div className='mt-4'>
        <table id='needs_attention_table' style={{width: '100%'}}>
          <thead>
            <tr>
              <th>needs attention reason</th>
              <th>creator</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {tickets.map((t) => {return this.renderTicketTr(t)})}
          </tbody>
        </table>
      </div>
    )
  }

  clickChangeUnoccupiedDate() {
    this.roomOccupiedModalRef.current.open(this.room.unoccupied_date_at);
  }

  handleLockedChange(e) {
    let val = e.target.value;
    this.room.locked = val === "true" ? true : (val === "false" ? false : "null")
    let locked = this.room.locked === 'null' ? null : this.room.locked;
    Room.update(this.room.id, {locked: locked});
    this.forceUpdate();
  };

  renderNote(note) {
    let key = `note_${note.id}`;

    return (
      <div key={key}>
        <span className='fw-500 mr-2'>On {note.friendly_created_at} {note.user.username} noted:</span>
        {note.note}
      </div>
    )
  }

  render() {
    let room_id = this.props.roomId;
    let room = this.room = App.room = rooms.find(r => r.id === room_id)

    if (!room) return null;

    let activity_data = room.getActivityData();

    let occupation_txt = room.unoccupied_date_at ? strftime('%m-%d-%Y @ %l:%M %p', room.unoccupied_date_at) : 'unknown';

    let field_notes = room.field_notes;

    return (
      <div>
        <h5 className='text-align-center mt-2'>
          {room.locked === true ? (<LockIcon />) : (room.locked === false ? (<LockOpenIcon />) : null ) }
          {room.occupied ? (<PersonIcon />) : null}
          {room.needs_attention ? (<WarningIcon />) : null}
          {room.showName()}
        </h5>

        <div className='mt-1 mb-4 d-flex justify-content-center'>
          <FormControl style={{width: 200}} >
            <InputLabel htmlFor="locked-select">Door status</InputLabel>
            <Select
              native
              value={isNull(this.room.locked) ? "null" : this.room.locked}
              onChange={this.handleLockedChange}
              inputProps={{
                name: 'locked',
                id: 'locked-select',
              }}
            >
              <option value={"null"}>Unknown</option>
              <option value={true}>Locked</option>
              <option value={false}>Unlocked</option>
            </Select>
          </FormControl>
        </div>

        <div className='d-flex align-items-center mt-1'>
          <Button onClick={this.clickOccupied} variant="outlined">
            Set Room {room.occupied ? 'Unoccupied' : 'Occupied'}
          </Button>
          <Button onClick={this.toggleNeedsAttention} variant="outlined" className='mx-1'>
            Needs Attention?
          </Button>
          <Button onClick={this.toggleFieldNoteModal} variant="outlined" >
            Add a Field Note
          </Button>
        </div>

        <div className='pl-2 mt-2'>
          { room.students.map(this.renderStudentRow) }

          { room.unoccupied_date_at &&
            <div style={{fontSize: 14}} className='mt-4 d-flex justify-content-between align-items-center pr-1'>
              {room.unoccupiedTimeElapsed() ?
                (<div>Room was set to unoccupied on: {occupation_txt}. You should be clear!</div>)
                :
                (<div>Room will be unoccupied on: {occupation_txt}</div>)
              }
              <div>
                <Button size="small" onClick={this.clickChangeUnoccupiedDate} variant="outlined"> Change </Button>
              </div>
            </div>
          }

          { this.renderTickets() }

          {room.purchased_installs.length > 0 &&
            <div>
              <div className='mt-2 text-secondary'>purchased products</div>

              <div className='d-flex'>
                {room.purchasedProductShortNames().map((short,index) => {
                  return (
                    <Button key={`button_purchased_${short}_${index + 1}`} variant="outlined"  className='mr-3'>
                    {short}
                    </Button>
                  )
                })}
              </div>
            </div>
          }

          { activity_data.length > 0 &&
            <div className='mt-4'>
              <div className='mt-2 text-secondary'>activity:</div>
              {activity_data.map(d => this.renderAction(d))}
            </div>
          }

          { field_notes.length > 0 &&
            <div className='mt-4'>
              <div className='mt-2 text-secondary'>field notes:</div>
              {field_notes.map(this.renderNote)}
            </div>
          }
        </div>

        {current_user.admin &&
          <div style={{marginTop: 60}} className="d-flex justify-content-center mb-4">
            <Button href={`/admin/rooms/${room.id}`} variant="outlined">Go To Admin Page</Button>
          </div>
        }

        <ProductActionModal innerRef={this.productActionModalRef} />
        <NeedsAttentionModal innerRef={this.modalRef} />
        <RoomUnoccupiedAtModal innerRef={this.roomOccupiedModalRef} />
        <FieldNoteModal innerRef={this.fieldNoteModalRef} />
      </div>
    )
  }
}

export default RoomView;

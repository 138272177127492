import React from 'react';
import ReactDOM from 'react-dom';

import Main from './components/main.jsx';

// append an element to the dom
window.appendElement = (componentName, element, props = {}) => {
  const component = React.createElement(getElement(componentName), props)

  window.drawer = ReactDOM.render(
    component,
    element.appendChild(document.createElement('div'))
  )
}

let getElement = (componentName) => {
  switch (componentName) {
    case 'Main': { return Main }
  }
}

import React from 'react';
import bindAll from 'lodash/bindAll';
import { findById, attemptParseInt, arrPresence } from '../util.js';
import { getViewState, getFilterState, getFilterText } from '../helpers/filter_helpers.js';
import uniq from 'lodash/uniq';
import merge from 'lodash/merge';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Select from '@material-ui/core/Select';

export default class FilterView extends React.PureComponent {
  constructor(props) {
    super(props);

    bindAll(this, ['handleChange', 'clickSaveFilter', 'clickReset']);

    this.state = Filter.getViewFilterStateOrNew();
  }

  handleToggle = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  handleChange(event) {
    const { options } = event.target;
    const values = [];
    const name = event.target.id.split('-')[0];

    var id;

    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        values.push(attemptParseInt(options[i].value));
      }
    }

    let obj = { [name]: values };

    this.setState(obj);
  };

  clickSaveFilter(e) {
    let data = merge({applied: true}, this.state);
    let filter_state = getFilterState(data);
    let filter = new Filter(filter_state);
    Filter.pushItem(filter);

    App.mainListView.prepareListForFilter(filter);
    App.router.goList()
  }

  clickReset(e) {
    this.setState(Filter.getNewViewFilterState());
  }

  render() {
    var level, buildings;
    const arrP = arrPresence;
    const u = uniq;

    let sids = this.state.school_ids;
    const all_areas = sids.length > 0 ? (
      uniq(window.buildings.filter(b => sids.includes(b.school_id)).map(b => b.area))
    ): [];

    let verb = 'Filter to be applied: ';
    let txt = getFilterText(verb, getFilterState(this.state));

    buildings = sids.length ? window.buildings.filter(b => sids.includes(b.school_id)) : window.buildings;

    if (this.state.areas.length) {
      buildings = buildings.filter(b => this.state.areas.includes(b.area));
    }

    let all_product_statuses = [
      {
        label: 'I want to uninstall shit',
        name: 'installed'
      },
      {
        label: 'I want to carry shit (removals)',
        name: 'uninstalled'
      },
      {
        label: 'I want to load shit on a truck',
        name: 'removed'
      },
      {
        label: 'See missing',
        name: 'missing'
      }
    ];

    let room_statuses = [
      {
        label: 'Unoccupied',
        name: 'unoccupied'
      },
      {
        label: 'Occupied',
        name: 'occupied'
      },
      {
        label: 'Needs Attention',
        name: 'needs_attention'
      }
    ];

    let door_statuses = [
      {
        label: 'Locked',
        name: 'locked'
      },
      {
        label: 'Unlocked',
        name: 'unlocked'
      },
      {
        label: 'Unknown',
        name: 'unknown'
      }
    ]

    return (
      <div className='p-2 mb-4 pt-3' id='filter-view'>
        <div className='d-flex justify-content-between align-items-start'>
          <FormControl className='fc'>
            <InputLabel shrink htmlFor="school_ids-filter">
              Select Schools
            </InputLabel>

            <Select inputProps={{id: 'school_ids-filter'}}
              multiple native
              value={this.state.school_ids}
              onChange={this.handleChange}
            >
              {window.schools.map(s => (
                <option key={`school_option_${s.id}`} value={s.id}>
                  {s.name}
                </option>
              ))}
            </Select>
          </FormControl>
          <Button onClick={this.clickReset} variant="outlined" color="primary">
            Reset
          </Button>
        </div>

          {sids.length > 0 &&
            <div>
              <div className='mt-3'>
                <FormControl className='fc'>
                  <InputLabel shrink htmlFor="areas-filter">
                    Select Areas
                  </InputLabel>

                  <Select inputProps={{id: 'areas-filter'}}
                    multiple native
                    value={this.state.areas}
                    onChange={this.handleChange}
                  >
                    {all_areas.map(name => (
                      <option key={`area_option_${name}`} value={name}>
                        {name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className='mt-3'>
                <FormControl className='fc'>
                  <InputLabel shrink htmlFor="building_ids-filter">
                    Select Buildings
                  </InputLabel>

                  <Select inputProps={{id: 'building_ids-filter'}}
                    multiple native
                    value={this.state.building_ids}
                    onChange={this.handleChange}
                  >
                    {buildings.map(b => (
                      <option key={`building_option_${b.id}`} value={b.id}>
                        {b.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          }

        <div className='mt-3'>
          <FormControl className='fc'>
            <InputLabel shrink htmlFor="product_ids-filter">
              Select Products
            </InputLabel>

            <Select inputProps={{id: 'product_ids-filter'}}
              multiple native
              value={this.state.product_ids}
              onChange={this.handleChange}
            >
              {window.products.map(p => (
                <option key={`product_option_${p.id}`} value={p.id}>
                  {p.name}
                </option>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className='mt-3'>
          <FormControl className='fc'>
            <InputLabel shrink htmlFor="product_statuses-filter">
              What do you want to do?
            </InputLabel>

            <Select inputProps={{id: 'product_statuses-filter'}}
              multiple native
              value={this.state.product_statuses}
              onChange={this.handleChange}
            >
              {all_product_statuses.map((s,i) => (
                <option key={`product_option_option_${i}`} value={i}>
                  {s.label}
                </option>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className='mt-3'>
          <FormControl className='fc'>
            <InputLabel shrink htmlFor="room_statuses-filter">
              Select Room Statuses
            </InputLabel>

            <Select inputProps={{id: 'room_statuses-filter'}}
              multiple native
              value={this.state.room_statuses}
              onChange={this.handleChange}
            >
              {room_statuses.map((s,i) => (
                <option key={`room_option_${i}`} value={s.name}>
                  {s.label}
                </option>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className='mt-3'>
          <FormControl className='fc'>
            <InputLabel shrink htmlFor="door_statuses-filter">
              Select Door Statuses
            </InputLabel>

            <Select inputProps={{id: 'door_statuses-filter'}}
              multiple native
              value={this.state.door_statuses}
              onChange={this.handleChange}
            >
              {door_statuses.map((s,i) => (
                <option key={`door_option_${s.name}`} value={s.name}>
                  {s.label}
                </option>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className='mt-3'>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.showAvailableJobs}
                onChange={this.handleToggle('showAvailableJobs')}
                value="showAvailableJobs"
                color="primary"
              />
            }
            label="Only show available jobs (rooms w/ unremoved products)"
          />
        </div>

        <p className='mt-1'>{txt}</p>
        <Button variant="outlined" color="primary" className='mt-1' onClick={this.clickSaveFilter}>
          Apply Filter
        </Button>
      </div>
    );
  }
}

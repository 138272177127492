import {findById} from './util.js';
import sortBy from 'lodash/sortBy';
import concat from 'lodash/concat';
import groupBy from 'lodash/groupBy';
import flattenDepth from 'lodash/flattenDepth';
import isString from 'lodash/isString';
import Room from './models/room.js';
import User from './models/user.js';
import Building from './models/building.js';
import Product from './models/product.js';
import Ticket from './models/ticket.js';
import Pickup from './models/pickup.js';
import Stack from './models/stack.js';
import FieldNote from './models/field_note.js';
import ProductInstallation from './models/product_installation.js';

export const handleData = function(current_user_id) {
  let schools = window.schools;

  window.buildings = window.buildings.map(b => new Building(b));
  window.products = window.products.map(p => new Product(p));
  window.installs = window.installs.map(i => new ProductInstallation(i));
  window.installs = sortBy(installs, i => i.school_id);
  window.rooms = window.rooms.map(r => new Room(r));
  window.students = window.students.map(u => new User(u));
  window.workers = window.workers.map(w => new User(w));
  window.current_user = findById(window.workers, current_user_id);
  window.tickets = window.tickets.map(t => new Ticket(t));
  window.pickups = window.pickups.map(p => new Pickup(p));
  window.stacks = window.stacks.map(s => new Stack(s));
  window.field_notes = window.field_notes.map(n => new FieldNote(n));

  concat(installs,rooms,buildings,tickets,pickups,field_notes, stacks).forEach(o => o.assignData());

  App.pickupPayscale = window.payscales.find(p => p.install_type == 2);
  App.stackPayscale = window.payscales.find(p => p.install_type == 3);
  App.deliveryPayscale = window.payscales.find(p => p.install_type == 4);

  window.rooms = window.rooms.filter(i => i.installs.length > 0);
  window.buildings = buildings.filter(b => window.rooms.find(r => r.building_id === b.id));

  window.flat_list = intersperseBuildings(window.rooms);
}

export const intersperseBuildings = function(rooms) {
  let grouped = groupBy(rooms, r => r.building.id);

  return flattenDepth(Object.entries(grouped), 2).map((i) => {
    return isString(i) ? (buildings.find(b => b.id == i)): i;
  });
}

export const collapseProds = function(prods) {
  let new_prods = {};

  Object.keys(prods).forEach((short) => {
    if (/^(TV|DFT|FT)/.test(short)) return;

    new_prods[short] = prods[short];
  });

  new_prods['TV'] = {
    1: prods['TV40'][1] + prods['TV32'][1],
    2: prods['TV40'][2] + prods['TV32'][2],
    total: prods['TV40'].total + prods['TV32'].total
  }

  new_prods['FT'] = {
    1: prods['DFT'][1] + prods['FT'][1],
    2: prods['DFT'][2] + prods['FT'][2],
    total: prods['DFT'].total + prods['FT'].total
  }

  return new_prods;
}

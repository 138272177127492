import isUndefined from 'lodash/isUndefined';
import intersection from 'lodash/intersection';
import strftime from 'strftime/strftime.js';

export const isPresent = function(string) {
  return typeof string === 'string' && string.trim() !== '';
}

export const isBlank = function(string) {
  return !isPresent(string);
}

export const presence = function(str) {
  return isPresent(str) ? str : null;
};

export const isArrPresent = function(arr) {
  return arr && arr.length > 0;
};

export const arrPresence = function(arr) {
  return arr && arr.length > 0 ? arr : null;
};

export const millisecondsToHours = function(milliseconds) {
  return parseFloat((milliseconds / (1000 * 60 * 60)).toFixed(1));
}

// andJoin(['a', 'b', 'c'])  => 'a, b & c'
export const andJoin = function(arr, delimiter = '&') {
  let len = arr.length;
  if (len === 0) return '';
  if (len === 1) return arr[0];
  if (len === 2) return `${arr[0]} ${delimiter} ${arr[1]}`;

  return arr.map((a,i) => {
    return i === len - 2 ? `${a} ${delimiter} ` : (i < len - 1 ? `${a}, `: a);
  }).join('');
}

export const doesIntersect = function(arr1, arr2) {
  return !!intersection(arr1, arr2).length;
}

export const isDefined = function(val) {
  return !isUndefined(val);
}

export const getFriendlyDate = function(date) {
  return strftime('%-m/%-d/%y %l:%M %p', date);
}

export const areSameDay = function(date1, date2) {
  if (!date1 || !date2) return false;

  return (date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth());
}

export const centsToDollarString = function(cents) {
  var dollars = cents / 100;
  return dollars.toLocaleString("en-US", {style:"currency", currency:"USD"});
}

export const shorterCentsToDollarString = function(cents) {
  let str = centsToDollarString(cents);

  str = /\.00$/.test(str) ? str.replace(/\.00$/, '') : str;
  return str.replace('$', '');
}

export const assignFriendlyDate = function(obj, prop, val) {
  let date_name = prop.replace(/_ts$/, '');
  let friendly_name = 'friendly_' + date_name;

  let js_date = new Date(val * 1000);
  obj[prop] = val;
  obj[date_name] = js_date;
  obj[friendly_name] = getFriendlyDate(js_date);
}

var ind;
export const findById = function(arr, id) {
  for (ind = 0; ind < arr.length; ind++) {
    if (arr[ind].id == id) return arr[ind];
  }

  return null;
}

var ho;
export const attemptParseInt = function(num) {
  ho = parseInt(num);

  return ho ? ho : num;
}

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';

  return s.charAt(0).toUpperCase() + s.slice(1);
};

import { assignFriendlyDate } from '../util.js';

class ApplicationObject {
  constructor(attrs) {
    this.resetData(attrs);
  }

  resetData(attrs) {
    for (let prop in attrs) {
      if (/_at_ts$/.test(prop)) {
        if (attrs[prop] && attrs[prop] > 0) {
          assignFriendlyDate(this, prop, attrs[prop]);
        } else {
          attrs[prop] = null;
          attrs[prop.replace(/_ts$/, '')] = null;
        }
      } else {
        this[prop] = attrs[prop];
      }
    }
  }
}

export default ApplicationObject;

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Link from '@material-ui/core/Link';

import FilterListIcon from '@material-ui/icons/FilterList';
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';
import FilterIcon from '@material-ui/icons/Filter';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import PeopleIcon from '@material-ui/icons/People';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import DescriptionIcon from '@material-ui/icons/Description';
import BuildIcon from '@material-ui/icons/Build';
import InfoIcon from '@material-ui/icons/Info';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import HomeIcon from '@material-ui/icons/Home';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import bindAll from 'lodash/bindAll';

const styles = {
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
};

class MainDrawer extends React.PureComponent {
  constructor(props) {
    super(props);

    bindAll(this, ['open', 'close', 'clickHome', 'clickCheatSheet', 'clickPickup']);
    this.state = {
      open: false
    }
  }

  clickHome(e) {
    App.router.goHome();
  }

  close() {
    this.setState({
      open: false
    })
  }

  open() {
    this.setState({
      open: true,
      hasFilters: !!App.filters.length
    })
  }

  clickFilter() {
    App.router.goFilter();
  }

  clickYourFilters() {
    App.router.goYourFilters();
  }

  clickCheatSheet() {
    App.router.goCheatSheet();
  }

  clickYourWork() {
    App.router.goYourWork();
  }

  clickPickup() {
    App.router.goPickups();
  }

  clickStacks() {
    App.router.goStacks();
  }

  clickList() {
    App.router.goList();
  }

  clickWorkers() {
    App.router.goWorkers();
  }

  render() {
    const { classes } = this.props;

    const sideList = (
      <div className={classes.list}>
        <List>
          <ListItem button key={'Home'} onClick={this.clickHome}>
            <ListItemIcon><HomeIcon /></ListItemIcon>
            <ListItemText primary={'Home'} />
          </ListItem>

          <ListItem button key={'Jobs'} onClick={this.clickList}>
            <ListItemIcon><BuildIcon /></ListItemIcon>
            <ListItemText primary={'Job List'} />
          </ListItem>

          <ListItem button key={'Filter'} onClick={this.clickFilter}>
            <ListItemIcon><FilterListIcon /></ListItemIcon>
            <ListItemText primary={'Filter'} />
          </ListItem>

          { this.state.hasFilters &&
            <ListItem button key={'Filters'} onClick={this.clickYourFilters}>
              <ListItemIcon><FilterIcon /></ListItemIcon>
              <ListItemText primary={'Your Filters'} />
            </ListItem>
          }

          <ListItem button key={'Workers'} onClick={this.clickWorkers}>
            <ListItemIcon><PeopleIcon /></ListItemIcon>
            <ListItemText primary={'Workers'} />
          </ListItem>

          <ListItem button key={'CheatSheet'} onClick={this.clickCheatSheet}>
            <ListItemIcon><InfoIcon /></ListItemIcon>
            <ListItemText primary={'Cheat Sheet'} />
          </ListItem>

          <Divider className='m-2' />


          <ListItem button key={'YourWork'} onClick={this.clickYourWork}>
            <ListItemIcon><WorkOutlineIcon /></ListItemIcon>
            <ListItemText primary={'Your Work'} />
          </ListItem>

          <Link href='/details_plz'>
            <ListItem button key={'Profile'}>
              <ListItemIcon><AccountCircleIcon /></ListItemIcon>
              <ListItemText primary={'Profile'} />
            </ListItem>
          </Link>

          <Link href='/logout' data-method='delete' rel='nofollow'>
            <ListItem button key={'Logout'} >
              <ListItemIcon><ExitToAppIcon /></ListItemIcon>
              <ListItemText primary={'Logout'} />
            </ListItem>
          </Link>

          <Divider className='m-2' />

          <ListItem button key={'Stacks'} onClick={this.clickStacks}>
            <ListItemIcon><HorizontalSplitIcon /></ListItemIcon>
            <ListItemText primary={'Stacks'} />
          </ListItem>

          <ListItem button key={'Truck Driver'} onClick={this.clickPickup}>
            <ListItemIcon><AirportShuttleIcon /></ListItemIcon>
            <ListItemText primary={'Truck Driver'} />
          </ListItem>

          { current_user.admin &&
            <div>
              <Divider className='m-2' />

              <Link href='/admin'>
                <ListItem button key={'Admin'} >
                  <ListItemIcon><DescriptionIcon /></ListItemIcon>
                  <ListItemText primary={'Admin'} />
                </ListItem>
              </Link>
            </div>
          }
        </List>
      </div>
    );

    return (
      <Drawer open={this.state.open} onClose={this.close}>
        <div
          tabIndex={0}
          role="button"
          onClick={this.close}
          onKeyDown={this.close}
        >
          {sideList}
        </div>
      </Drawer>
    );
  }
}

MainDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MainDrawer);

import React from 'react';
import {AppComponent} from '../app_components';
import bindAll from 'lodash/bindAll';
import sortBy from 'lodash/sortBy';

import strftime from 'strftime/strftime.js';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { attemptParseInt, andJoin } from '../util.js';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import Select from '@material-ui/core/Select';

class StacksView extends AppComponent {
  constructor(props) {
    super(props);

    bindAll(this, ['clickRow', 'renderStackRow']);
  }

  clickNewStack() {
    App.router.goNewStack();
  }

  clickRow(s) {
    App.router.goStack(s.id);
  }

  renderStackRow(s) {
    let date = strftime('%-m/%-d/%y ~ %l:%M %p', s.created_at);

    let workers = andJoin(s.workers.map(w => w.username));

    return (
      <div key={'prow_' + s.id} onClick={() => { this.clickRow(s) }}
        className='stack-row list-group-item d-flex justify-content-between align-items-center p-1'>
        <div>
          {workers} stacked {s.bl_count} bedlofts on {date}
        </div>
        <ArrowForwardIcon />
      </div>
    )
  }

  render() {
    let stacks = window.stacks;
    return (
      <div className='p-1 mt-2'>
        <div className='d-flex align-items-center justify-content-between'>
          <h5>Stacks ({stacks.length})</h5>
          <button onClick={this.clickNewStack} type="button" className="btn btn-outline-dark">New Stack</button>
        </div>
        <div id='stacks' className='mt-3'>
          {stacks.map(this.renderStackRow)}
        </div>
      </div>
    );
  }
}

export default StacksView;

import ApplicationObject from './application_object.js';
import pick from 'lodash/pick';
import { postData } from '../fetch_helpers.js';
import { findById } from '../util.js';

const cols = ['id', 'text', 'room_id', 'user_id', 'assigned_to_id',
'assigned_at', 'resolved_by_id', 'resolved_at', 'created_at'];

class Ticket extends ApplicationObject {
  constructor(attrs) {
    super(attrs);
  }

  static create(room_id, data, cb) {
    let params = { room_id: room_id, ticket: data };

    postData(`/tickets.json`, params)
      .catch(error => alert('an error occurred'))
      .then((json) => {
        if (json) {
          let ticket = new Ticket(json);
          ticket.assignData();

          window.tickets.push(ticket);
          let room = ticket.room;
          ticket.room.assignData();

          App.roomView.forceUpdate()
          if (cb) cb()
        }
      });
  }

  static markResolved(room_id, ticket_id, cb) {
    let params = {room_id: room_id, ticket_id: ticket_id};

    postData(`/tickets/${ticket_id}/mark_resolved`, params)
      .catch(error => alert('an error occurred'))
      .then((json) => {
        if (json) {
          let ticket = findById(window.tickets, json.id);

          ticket.resetData(json);
          ticket.assignData();
          ticket.room.assignData();

          App.roomView.forceUpdate()
          if (cb) cb()
        }
      });
  }

  static markUnresolved(room_id, ticket_id, cb) {
    let params = {room_id: room_id};

    return postData(`/tickets/${ticket_id}/mark_unresolved`, params)
      .catch(error => alert('an error occurred'))
      .then((json) => {
        if (json) {
          let ticket = findById(window.tickets, json.id);

          ticket.resetData(json);
          ticket.assignData();
          ticket.room.assignData();

          App.roomView.forceUpdate()
          if (cb) cb()
        }
      });
  }

  assignData() {
    let workers = window.workers;

    if (this.resolved_by_id) {
      this.resolved_by = findById(workers, this.resolved_by_id);
    }

    if (this.assigned_to_id) {
      this.assigned_to = findById(workers, this.assigned_to_id);
    }

    this.room = findById(window.rooms, this.room_id);
    this.creator = findById(workers, this.creator_id);
  }

  toJSON() {
    return pick(this, cols);
  }
}

export default Ticket;

export const makeRequest = function(path, method, payload) {
  const options = {
    method: method,
    body: JSON.stringify(payload),
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'same-origin'
  }

  return fetch(path, options).then(r => r.json());
}

export const postData = function(path, payload) {
  return makeRequest(path, 'POST', payload);
}

export const patchData = function(path, payload) {
  return makeRequest(path, 'PATCH', payload);
}

export const getData = function(path, payload) {
  return makeRequest(path, 'GET', payload);
}

import React from 'react';
import Typography from '@material-ui/core/Typography';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
import PersonIcon from '@material-ui/icons/Person';
import WarningIcon from '@material-ui/icons/Warning';
import RefreshIcon from '@material-ui/icons/Refresh';

export default class CheatSheetView extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div id='cheatsheet' className='p-1'>
        <div>
          <RefreshIcon /> = refresh data
        </div>
        <Typography variant='h6'>Product colors:</Typography>
        <div className='text-danger my-1'>
          red color means no action has been taken on the product.  it's pending uninstallation and removal
        </div>
        <div className='text-warning my-1'>
          yellow color indicates an uninstalled product
        </div>
        <div className='text-info my-1'>
          blue color indicates a removed product
        </div>

        <Typography variant='h6'>Room Icons:</Typography>
        <div><LockOpenIcon /> room unlocked</div>
        <div><LockIcon /> room locked</div>
        <div><PersonIcon /> room occupied</div>
        <div><WarningIcon /> room needs attention</div>

        <Typography variant='h6'>Product acronyms:</Typography>
        <div id='acronym-list'>
          <div>
            <span className='fw-500'>BL:</span>
            Bedloft
          </div>
          <div>
            <span className='fw-500'>MF:</span>
            Microfridge
          </div>
          <div>
            <span className='fw-500'>SR:</span>
            Safety Rail
          </div>
          <div>
            <span className='fw-500'>HB:</span>
            Headboard
          </div>
          <div>
            <span className='fw-500'>DFT:</span>
            Deluxe Futon
          </div>
          <div>
            <span className='fw-500'>FT:</span>
            (Standard) Futon
          </div>
          <div>
            <span className='fw-500'>MF:</span>
            Microfridge
          </div>
          <div>
            <span className='fw-500'>ES:</span>
            Electronic Safe
          </div>
          <div>
            <span className='fw-500'>FB:</span>
            Full bed
          </div>
          <div>
            <span className='fw-500'>TV32:</span>
            32" TV
          </div>
          <div>
            <span className='fw-500'>TV40:</span>
            40" TV
          </div>
        </div>
      </div>
    )
  }
}

import pick from 'lodash/pick';
import map from 'lodash/map';

import { andJoin, findById, attemptParseInt, arrPresence, doesIntersect } from '../util.js';

const status_map = {
  0: 'pending uninstallation',
  1: 'uninstalled',
  2: 'carried/removed',
  3: 'missing'
}

const base_attrs = ['id', 'product_statuses',
'showAvailableJobs', 'room_statuses', 'door_statuses', 'areas', 'applied', 'barHeight'];

export const getFilterText = function(txt = 'Showing', state, prod_counts) {
  var {buildings, schools, areas, showAvailableJobs, room_statuses, door_statuses, product_statuses, products} = state;

  room_statuses = room_statuses.map(s => s === 'needs_attention' ? 'need attention' : s);

  let schoolsTxt = andJoin(schools.map(s => s.name));
  let schoolsPlural = schools.length > 1 ? 'schools' : 'school';
  let jobs = showAvailableJobs ? 'available jobs' : 'all jobs';

  txt = `${txt} ${jobs}`;

  if (room_statuses.length) {
    let tobe = room_statuses.find(s => /occupied$/.test(s)) ? 'are ' : '';
    txt = `${txt} that ${tobe}${andJoin(room_statuses, 'OR')}`;
  }

  txt = door_statuses.length ?
    (`${txt} for ${andJoin(door_statuses, 'OR')} doors`) : txt;
  txt = schools.length ? `${txt} for ${schoolsPlural}: ${schoolsTxt}` : txt;

  if (buildings.length) {
    let plural = buildings.length > 1 ? 'buildings' : 'building';
    txt += `; ${plural}: ${andJoin(buildings.map(b => b.name))}`;
  } else if (areas.length) {
    let plural = areas.length > 1 ? 'areas': 'area';
    txt += `; ${plural}: ${andJoin(areas)}`;
  }

  if (products.length) {
    let plural = products.length > 1 ? 'products' : 'product';
    if (prod_counts) {
      let names = map(prod_counts, (cnt, short) => {
        return `${short} (${cnt})`;
      });
      txt += `; ${plural}: ${andJoin(names)}`;
    } else {
      txt += `; ${plural}: ${andJoin(products.map(p => p.short))}`;
    }
  }

  if (product_statuses.length) {
    let plural = product_statuses.length > 1 ? 'statuses' : 'status';
    let statuses = product_statuses.map(s => status_map[s]);
    txt += `; ${plural}: ${andJoin(statuses)}`;
  }

  return txt;
}

const getBaseFilterAttrs = function(obj) {
  return pick(obj, base_attrs);
}

export const getFilterState = function(state) {
  let obj = getBaseFilterAttrs(state);

  obj.schools = state.school_ids.map(sid => findById(window.schools, sid));
  obj.buildings = state.building_ids.map(bid => findById(window.buildings, bid));
  obj.products = state.product_ids.map(pid => findById(window.products, pid));

  return obj;
}

export const getViewState = function(obj) {
  let state = getBaseFilterAttrs(obj);

  state.school_ids =  obj.schools.map(s => s.id);
  state.product_ids = obj.products.map(p => p.id);
  state.building_ids = obj.buildings.map(b => b.id);

  return state;
}

export const getFilteredRooms = function(obj) {
  var {buildings, schools, areas, showAvailableJobs, room_statuses, door_statuses, products, product_statuses} = obj;

  let rooms = window.rooms;

  if (schools.length) {
    let sids = schools.map(s => s.id);
    rooms = rooms.filter(r => sids.includes(r.school.id));
  }

  if (buildings.length) {
    let bids = buildings.map(b => b.id);
    rooms = rooms.filter(r => bids.includes(r.building_id));
  } else if (areas.length) {
    rooms = rooms.filter(r => areas.includes(r.building.area));
  }

  let stat_rooms = rooms;

  rooms = showAvailableJobs ? rooms.filter(r => r.hasAvailableJobs()) : rooms;

  rooms = room_statuses.length ? rooms.filter((r) => {
    return !!room_statuses.find(s => r[s])
  }) : rooms;

  rooms = door_statuses.length ? rooms.filter((r) => {
    return !!door_statuses.find(s => r.hasDoorStatus(s));
  }) : rooms;

  let filter_product_ids = products.map(p => p.id);
  if (products.length && product_statuses.length) {
    rooms = rooms.filter((r) => {
      return r.installs.find((i) => {
        return filter_product_ids.includes(i.product.id) && product_statuses.includes(i.status);
      });
    });
  } else {
    var room_product_ids;
    rooms = products.length ? rooms.filter((r) => {
      room_product_ids = r.products.map(p => p.id);
      return doesIntersect(filter_product_ids, room_product_ids);
    }) : rooms;

    rooms = product_statuses.length ? rooms.filter((r) => {
      return doesIntersect(r.getStatuses(), product_statuses);
    }) : rooms;
  }


  return [rooms, stat_rooms];
}

import flatten from 'lodash/flatten';
import clone from 'lodash/clone';
import sum from 'lodash/sum';
import {arrPresence} from './util.js';
import {collapseProds} from './app_specific_utils.js';

import ProductInstallation from './models/product_installation.js';
import Pickup from './models/pickup.js';

export const getAllStats = function(filter_occupied) {
  return schools.map(s => getStatsForSchool(s, window.installs, filter_occupied));
}

export const getStatsForBuilding = function(building, rooms) {
  rooms = rooms.filter(r => r.building.id === building.id);
  let installs = flatten(rooms.map(r => r.installs));

  let stats = {};
  var short,install;

  for (let i = 0; i < installs.length; i++) {
    install = installs[i];
    short = install.product.short;
    stats[short] = stats[short] || {total: 0, 0: 0, 1: 0, 2: 0};
    stats[short].total += 1;
    stats[short][install.status] += 1;
  }

  return stats;
}

export const getStatsForFilter = function(filter, filter_occupied = false) {
  let [_, rooms] = filter.getRooms();
  let schools = arrPresence(filter.schools) || window.schools;
  let installs = flatten(rooms.map(r => r.installs));

  return schools.map(s => getStatsForSchool(s, installs, filter_occupied));
};

export const getStatsForSchool = function(school, installs, filter_occupied = false) {
  let prods = {};
  let stats = {
    school: school,
    prods: prods
  };

  let all_shorts = ProductInstallation.getShorts();

  all_shorts.forEach(s => prods[s] = {total: 0, 0: 0, 1:0, 2:0});

  installs = (installs || window.installs).filter(i => i.school.id === school.id);

  if (filter_occupied) {
    installs = installs.filter(i => !i.room.occupied);
  }

  var install, short;

  for (let i = 0; i < installs.length; i++) {
    install = installs[i];
    short = install.product.short;
    prods[short].total += 1;
    prods[short][install.status] += 1;
  }

  return stats;
};

export const calcPickups = function(pickups) {
  let shorts = Pickup.getShorts();
  let obj = {};

  shorts.forEach((s) => {
    obj[s] = sum(pickups.map(p => p[s + '_count']));
  });

  return obj;
}

export const getStatsForUser = function(user, installs) {
  let prods = {};

  installs = installs || window.installs;

  let all_shorts = ProductInstallation.getShorts();

  all_shorts.forEach(s => prods[s] = {total: 0, 1:0, 2:0, 3:0});

  var install, short;

  for (let i = 0; i < installs.length; i++) {
    install = installs[i];
    short = install.product.short;
    if (install.hasBeenTouchedBy(user)) {
      let touches = install.touchesBy(user);

      prods[short].total += 1;

      Object.entries(touches).forEach((e) => {
        prods[install.product.short][e[0]] += 1;
      });
    }
  }

  return prods;
}

export const getMoneyForStats = function(stats, payscales) {
  let uninstall_payscale = payscales.find(p => p.install_type === 0);
  let removal_payscale = payscales.find(p => p.install_type === 1);

  let money = {};

  Object.keys(stats).forEach((short) => {
    let numbers = stats[short];
    if (!numbers.total) return;

    short = /^TV/.test(short) ? 'TV' : short;

    let s = {};
    let cents_name = short.toLowerCase() + '_cents';

    s.uninstall_amt = uninstall_payscale[cents_name] * numbers[1];
    s.removal_amt = removal_payscale[cents_name] * numbers[2];
    s.total_amt = s.uninstall_amt + s.removal_amt;

    money[short] = s;
  });

  return money;
}

export const getStatsForUserByDay = function(user, date) {
  let prods = {};

  var install, short;

  let all_shorts = ProductInstallation.getShorts();

  all_shorts.forEach(s => prods[s] = {total: 0, 1:0, 2:0, 3:0});

  for (let i = 0; i < installs.length; i++) {
    install = installs[i];
    short = install.product.short;

    if (install.hasTouchInDate(user, date)) {
      let touches = install.touchesByUserOnDate(user, date);

      prods[short].total += 1;

      Object.entries(touches).forEach((e) => {
        prods[install.product.short][e[0]] += 1;
      });
    }
  }

  return prods;
}

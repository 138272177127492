import React from 'react';
import {AppComponent} from '../app_components';

import bindAll from 'lodash/bindAll';
import lowerCase from 'lodash/lowerCase';

import { findById, centsToDollarString, isPresent } from '../util.js';

import ProductInstallation from '../models/product_installation.js';

class ShowPickup extends AppComponent {
  constructor(props) {
    super(props);

    bindAll(this, ['renderShortRow', 'renderCalcTable', 'clickEdit']);
  }

  renderShortRow(s) {
    let ls = lowerCase(s);
    let cnt = this.pickup[ls + '_count'];
    let cents = this.payscale[ls + '_cents'];
    let total = cnt * cents;

    return (
      <tr key={`td_${s}`}>
        <td className='fw-500'>{s}:</td>
        <td>{cnt}</td>
        <td className='px-3'>{centsToDollarString(cents)}</td>
        <td>{centsToDollarString(total)}</td>
      </tr>
    )
  }

  renderCalcTable(shorts) {
    let t = this.pickup.total();
    let total_cents = this.pickup.totalCents(this.payscale);
    let each_made_cents = (total_cents / this.pickup.workers.length)

    return (
      <div>
        <table id='pickup_calc_table'>
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {shorts.map(this.renderShortRow)}
            <tr>
              <td className='fw-500'>Total:</td>
              <td>{t}</td>
              <td></td>
              <td className='fw-500'>{centsToDollarString(total_cents)}</td>
            </tr>
          </tbody>
        </table>
        <div className='fw-500 mt-3'>
          Each worker earned {centsToDollarString(each_made_cents)}
        </div>
      </div>
    )
  }

  clickEdit() {
    App.router.editPickup(this.pickup.id);
  }

  render() {
    let shorts = ProductInstallation.getCollapsedShorts();
    let pickup = this.pickup = window.pickups.find(p => p.id == this.props.pickupId);

    let workers = pickup.worker_ids.map(id => findById(window.workers, id));
    let buildings = pickup.building_ids.map(id => findById(window.buildings, id));
    let payscale = this.payscale = window.payscales.find(p => p.install_type == 2);

    return (
      <div className='p-1 mt-2'>
        { (current_user.admin || pickup.creator_id === current_user.id) &&
          <div className='my-2 d-flex justify-content-between align-items-center'>
            <button onClick={this.clickEdit} type="button" className="btn btn-outline-dark">Edit</button>
          </div>
        }

        <div>
          <span className='fw-500 mr-2'>Creator:</span>
          { pickup.creator.username }
        </div>

        <div>
          <span className='fw-500 mr-2'>School:</span>
          { pickup.school.name }
        </div>

        {buildings.length > 0 &&
          <div>
            <span className='fw-500 mr-2'>Buildings:</span>
            { buildings.map(b => b.name).join(', ') }
          </div>
        }

        {workers.length > 0 &&
          <div>
            <span className='fw-500 mr-2'>Workers:</span>
            { workers.map(w => w.username).join(', ') }
          </div>
        }

        {this.renderCalcTable(shorts)}

        {isPresent(pickup.text) &&
          <div className='mt-3'>
            <span className='fw-500 mr-2'>Notes:</span>
            {pickup.text}
          </div>
        }

      </div>
    );
  }
}

export default ShowPickup;

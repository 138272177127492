import React from 'react';

import classnames from 'classnames';
import { intersperseBuildings } from '../app_specific_utils.js';
import { getStatsForBuilding } from '../stats_helper.js';

import bindAll from 'lodash/bindAll';

import ProductInstallation from '../models/product_installation.js';

import Link from '@material-ui/core/Link';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import PersonIcon from '@material-ui/icons/Person';
import WarningIcon from '@material-ui/icons/Warning';

import { VariableSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

var key2 = 9999;
class MainList extends React.Component {
  ref = React.createRef();
  constructor(props) {
    super(props);

    const { classes } = this.props;
    this.classes = classes;
    this.section_names = [];
    this.listToggle = true;
    App.mainListView = this;
    bindAll(this, ['renderRow', 'setSchoolFilter', 'clickRow', 'render', 'clickFilterRow', 'onScroll', 'clickBuilding']);

    this.state = {}
  }

  shouldComponentUpdate() {
    return false;
  }

  clickRow(e, room_id) {
    App.router.goRoom(room_id);
  }

  setSchoolFilter(school_id) {
    this.setState({
      schoolFilterId: school_id ? school_id : null
    })
  }

  renderShort(i) {
    let klass = i.purchase ? 'short purchase' : (classnames('short', {
      'text-danger': i.status === 0 ,
      'text-warning': i.status === 1,
      'text-info': i.status === 2
    }));

    return (
      <div key={`short_${i.id}`} className='pl-2'>
        <span className={klass}>{i.product.short}</span>{i.letter() ?
            (<span className='letter'>{i.letter()}</span>) : null}
      </div>
    )
  }

  renderSpans(totals, short) {
    if (!totals) return null;

    let total = totals.total;
    if (!total) return null;

    let installed = totals[0];
    let uninstalled = totals[1];
    let removed = totals[2];

    return (
      <span key={key2+=1}>
        <span className='mx-1'>{short} ({total},
          <span className='text-danger'>{installed}</span>,
          <span className='text-warning'>{uninstalled}</span>,
          <span className='text-info'>{removed}</span>)
        </span>
      </span>
    )
  }

  clickBuilding(e, building) {
    App.router.goBuilding(building.id);
  }

  renderRow = ({ index, style }) => {
    this.index = index;

    let item = this.current_list[index];

    let klass = classnames(
      'pl-2 d-flex align-items-center list-group-item',
      {'building': item.isBuilding()}
    )

    if (item.isBuilding()) {
      let building = item;
      let stats = getStatsForBuilding(building, this.stats_rooms);
      let shorts = ProductInstallation.getShorts();

      return (
        <div title={building.schoolName()} className={klass} style={style} onClick={e => this.clickBuilding(e, building)}>
          <span className='fw-500'>{building.name}:</span>
          <div style={{fontSize: 13}}>{shorts.map(s => this.renderSpans(stats[s], s))}</div>
        </div>
      )
    } else {
      let room = item;
      let installs = ProductInstallation.sortByShort(room.installs);

      return (
        <div id={`room_row_${room.id}`} onClick={(e) => {this.clickRow(e, room.id)}} className={klass} style={style}>
          {room.locked ? (<LockIcon />) : (room.locked === false ? (<LockOpenIcon />) : null ) }
          {room.occupied ? (<PersonIcon />) : null}
          {room.needs_attention ? (<WarningIcon />) : null}
          {room.wingName()}: {installs.map(i => this.renderShort(i))}
        </div>
      )
    }
  };

  clickFilterRow(e) {
    App.router.goFilterStats();
  }

  prepareListForFilter() {
    this.title = null;
    this.listToggle = !this.listToggle;
    this.section_names = [];
    this.list_hold = null;
  }

  onScroll(e) {
    if (this.list_hold) {
      var ele, title;
      if (this.list_hold.scrollTop < 40) {
        ele = this.list_hold.children[0];
      }

      if (e.scrollDirection === 'forward') {
        ele = ele || this.list_hold.children[1];
        if (ele && ele.classList.contains('building')) {
          title = ele.getAttribute('title');
          if (!this.section_names.includes(title)) {
            this.section_names.push(title);
          }
        }
      } else if (e.scrollDirection === 'backward') {
        ele = this.list_hold.children[2];

        if (ele && ele.classList.contains('building') && this.section_names.includes(ele.getAttribute('title'))) {
          this.section_names.pop();
          title = this.section_names[this.section_names.length - 1];
        }
      }

      if (title) {
        this.title = title;
        App.appBar.setState({ title: title });
      }
    } else {
      this.list_hold = document.querySelector('.List').children[0];
    }
  }

  getTitle() {
    return this.title || 'Removals';
  }

  render() {
    var rooms, stats_rooms;

    let filter = Filter.getCurrentFilter();

    [rooms, stats_rooms] = filter ? filter.getRooms() : [window.rooms];

    let current_list = this.current_list = filter ? intersperseBuildings(rooms) : window.flat_list;
    this.stats_rooms = stats_rooms || rooms;

    var filter_title;
    if (filter) {
      filter_title = filter.getListText(rooms);
    } else {
      filter_title = `Showing all rooms; ${rooms.length} rooms in list`;
    }

    // force list rerender to show updates to rooms
    this.height = this.height === 69 ? 70 : 69;

    let barHeight = 50;
    let h = window.innerHeight - (barHeight) - this.height;

    let filterTitleKlass = classnames({'txt-row': !!filter});
    return (
      <div style={{height: h, width: '100%'}}>
        <div id='filter_bar' className='d-flex align-items-center justify-content-between' onClick={this.clickFilterRow}>
          <div className='txt-hold mr-1 p-1 d-flex flex-nowrap'>
            <div className={filterTitleKlass}>{filter_title}</div>
            <ArrowForwardIcon />
          </div>
        </div>

        {this.listToggle === true &&
          <AutoSizer>
            {({ height, width }) => (
              <List
                onScroll={this.onScroll}
                className="List"
                height={h}
                itemCount={current_list.length}
                itemSize={i => current_list[i].isBuilding() ? 62 : 50}
                width={width}
              >
                {this.renderRow}
              </List>
            )}
          </AutoSizer>
        }

        {this.listToggle === false &&
          <AutoSizer>
            {({ height, width }) => (
              <List
                onScroll={this.onScroll}
                className="List"
                height={h}
                itemCount={current_list.length}
                itemSize={i => current_list[i].isBuilding() ? 62 : 50}
                width={width}
              >
                {this.renderRow}
              </List>
            )}
          </AutoSizer>
        }
      </div>
    );
  }
}

export default MainList;

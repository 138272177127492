import ApplicationObject from './application_object.js';

import {postData, patchData} from '../fetch_helpers.js';
import sum from 'lodash/sum';
import clone from 'lodash/clone';
import sumBy from 'lodash/sumBy';
import {findById, areSameDay} from '../util.js';

var working = false;

class Stack extends ApplicationObject {
  toJSON() {
    let json = {};

    json.worker_ids = clone(this.worker_ids);
    json.text = this.text;
    json.bl_count = this.bl_count;

    return json;
  }

  static create(attrs) {
    if (working) return;

    working = true;

    let params = {stack: attrs};

    postData('/stacks', params)
     .catch(error => alert('an error occurred'))
     .then((json) => {
       if (json) {
         let stack = new Stack(json);
         stack.assignData();
         window.stacks.push(stack);
         App.router.goStack(stack.id);
       }

       working = false;
     });
  }

  static update(stack, attrs) {
    if (working) return;

    working = true;

    let params = {stack: attrs};

    patchData(`/stacks/${stack.id}`, params)
     .catch(error => alert('an error occurred'))
     .then((json) => {
       if (json) {
         stack.resetData(json);
         stack.assignData();
         App.router.goStack(stack.id);
       }

       working = false;
     });
  }

  static stacksForUserAndDate(user,date) {
    let all_stacks = window.stacks;
    var stack;
    var stacks = [];

    var i, sameday, has_user;

    for (i = 0; i < all_stacks.length; i++) {
      stack = all_stacks[i];

      sameday = areSameDay(stack.created_at, date);
      has_user = stack.worker_ids.includes(user.id);

      if (sameday && has_user) {
        stacks.push(stack);
      }
    }

    return stacks;
  }

  static stacksForUser(user) {
    let all_stacks = window.stacks;

    var stack;
    var stacks = [];

    var i, has_user;

    for (i = 0; i < all_stacks.length; i++) {
      stack = all_stacks[i];

      has_user = stack.worker_ids.includes(user.id);

      if (has_user) {
        stacks.push(stack);
      }
    }

    return stacks;
  }


  static totalCentsForUserForDate(user, date) {
    return sumBy(Stack.stacksForUserAndDate(user, date), s => s.individualPortionCents());
  }

  static totalCentsForUser(user) {
    return sumBy(Stack.stacksForUser(user), s => s.individualPortionCents());
  }

  totalCents(payscale) {
    return this.bl_count * payscale.bl_cents;
  }

  individualPortionCents() {
    let payscale = App.stackPayscale;
    return this.totalCents(payscale) / this.worker_ids.length;
  }

  assignData() {
    this.workers = this.worker_ids.map(wid => findById(window.workers, wid));
    this.creator = findById(window.workers, this.creator_id);
  }
}

export default Stack;

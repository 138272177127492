import React from 'react';
import bindAll from 'lodash/bindAll';

import PropTypes from 'prop-types';
import { findById } from '../util.js';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import RefreshIcon from '@material-ui/icons/Refresh';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import {refreshData} from '../data_helpers.js';

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
};

const drill_views = ['Room', 'FilterStats', 'ShowPickup', 'PickupForm', 'ShowStack', 'StackForm', 'Building', 'ShowWorker'];

class MainAppBar extends React.PureComponent {
  constructor(props) {
    super(props);

    App.appBar = this;

    bindAll(this, 'clickRefresh');

    this.classes = props.classes;
    this.state = {loading: false};
  }

  clickRefresh() {
    this.setState({loading: true});

    refreshData(() => {
      this.setState({loading: false});
    });
  }

  render() {
    let vs = this.props.viewShown;
    let is_drill = drill_views.includes(vs);
    var title;

    switch (vs) {
      case 'Home':
        title = 'Home';
        break;
      case 'List':
        title = App.mainListView ? App.mainListView.getTitle() : 'Removals'
        break;
      case 'Room':
        title = findById(window.rooms, this.props.roomId).school.name;
        break
      case 'Filter':
        title = 'Filter Rooms';
        break
      case 'YourFilters':
        title = 'Your Filters';
        break
      case 'FilterStats':
        title = 'Filter Stats';
        break
      case 'CheatSheet':
        title = 'Cheat Sheet';
        break
      case 'Building':
        title = findById(window.buildings, this.props.buildingId).name;
        break
      case 'YourWork':
        title = 'Your Work';
        break
      case 'ShowWorker':
        title = `${findById(window.workers, this.props.workerId).username}'s work`;
        break
    }

    title = /Pickup/.test(vs) ? 'Truck Driver' : title;
    title = /stack/i.test(vs) ? 'Bedloft Stacks' : title;

    title = title || 'Removals';

    return (
      <div>
        <AppBar id='appbar' position="static">
          <Toolbar className='d-flex justify-content-between'>
            <div className='d-flex align-items-center'>
              <IconButton onClick={this.props.clickMenu} className={this.classes.menuButton} color="inherit" aria-label="Menu">
                {is_drill ?
                    <ArrowBackIos /> : <MenuIcon /> }
              </IconButton>
              { title !== 'Removals' &&
                <div>{title}</div>
              }

              { title === 'Removals' &&
                <Typography variant="h6" color="inherit" className={this.classes.grow}>
                  Removals
                </Typography>
              }
            </div>

            <div style={{color: this.state.loading ? 'orange' : 'white'}}>
              <RefreshIcon onClick={this.clickRefresh} />
            </div>
          </Toolbar>
        </AppBar>
      </div>
    )
  }
}




export default withStyles(styles)(MainAppBar);

import React from 'react';
import bindAll from 'lodash/bindAll';

class HomeView extends React.Component {
  constructor(props) {
    super(props);

    bindAll(this, []);
  }

  render() {
    return (
      <h2>Home</h2>
    );
  }
}

export default HomeView;

import React from 'react';
import {AppComponent} from '../app_components';

import bindAll from 'lodash/bindAll';
import lowerCase from 'lodash/lowerCase';

import { findById, centsToDollarString, isPresent } from '../util.js';

class ShowStack extends AppComponent {
  constructor(props) {
    super(props);

    bindAll(this, ['clickEdit']);
  }

  clickEdit() {
    App.router.editStack(this.stack.id);
  }

  render() {
    let stack = this.stack = window.stacks.find(p => p.id == this.props.stackId);

    let workers = stack.worker_ids.map(id => findById(window.workers, id));
    let payscale = App.stackPayscale;

    let cnt = this.stack.bl_count;
    let cents = payscale.bl_cents;
    let total = cnt * cents;

    return (
      <div className='p-1 mt-2'>
        { (current_user.admin || stack.creator_id === current_user.id) &&
          <div className='my-2 d-flex justify-content-between align-items-center'>
            <button onClick={this.clickEdit} type="button" className="btn btn-outline-dark">Edit</button>
          </div>
        }

        <div>
          <span className='fw-500 mr-2'>Creator:</span>
          { stack.creator.username }
        </div>

        {workers.length > 0 &&
          <div>
            <span className='fw-500 mr-2'>Workers:</span>
            { workers.map(w => w.username).join(', ') }
          </div>
        }

        <p><span className='fw-500 mr-2'>Pay:</span> {cnt} x {centsToDollarString(cents)} = {centsToDollarString(total)}</p>

        <p><span className='fw-500 mr-2'>Each worker made:</span> {centsToDollarString(total / workers.length)}</p>

        {isPresent(stack.text) &&
          <div className='mt-3'>
            <span className='fw-500 mr-2'>Notes:</span>
            {stack.text}
          </div>
        }
      </div>
    );
  }
}

export default ShowStack;

import ApplicationObject from './application_object.js';
import {postData, patchData} from '../fetch_helpers.js';
import sum from 'lodash/sum';
import clone from 'lodash/clone';
import sumBy from 'lodash/sumBy';
import {findById, areSameDay} from '../util.js';

var working = false;
const shorts = ['bl', 'mf', 'sr', 'bs', 'es', 'tv', 'ft'];
const attrs = shorts.map(s => s + '_count');
const scents = shorts.map(s => s + '_cents');

class Pickup extends ApplicationObject {
  constructor(attrs) {
    super(attrs);
  }

  total() {
    return sum(attrs.map(a => this[a]));
  }

  totalCents(payscale) {
    let all_cents = shorts.map((s,i) => {
      let cnt = this[attrs[i]];
      let cents = payscale[scents[i]];

      return cnt * cents;
    });

    return sum(all_cents);
  }

  toJSON() {
    let json = {};

    json.building_ids = clone(this.building_ids);
    json.worker_ids = clone(this.worker_ids);
    json.school_short = this.school.short;
    json.text = this.text;
    attrs.forEach(a => json[a] = this[a]);

    return json;
  }

  static getShorts() {
    return shorts;
  }

  static getAttrs() {
    return attrs;
  }

  static create(school_id, attrs) {
    if (working) return;

    working = true;

    let params = {school_id: school_id, pickup: attrs};

    postData('/pickups', params)
     .catch(error => alert('an error occurred'))
     .then((json) => {
       if (json) {
         let pickup = new Pickup(json);
         pickup.assignData();
         window.pickups.push(pickup);
         App.router.goPickup(pickup.id);
       }

       working = false;
     });
  }

  static update(pickup, attrs) {
    if (working) return;

    working = true;

    let params = {pickup: attrs};

    patchData(`/pickups/${pickup.id}`, params)
     .catch(error => alert('an error occurred'))
     .then((json) => {
       if (json) {
         pickup.resetData(json);
         pickup.assignData();
         App.router.goPickup(pickup.id);
       }

       working = false;
     });
  }

  static truckDriveForUserAndDate(user, date) {
    let all_pickups = window.pickups;
    var pickup;
    var pickups = [];

    var i, sameday, has_user;

    for (i = 0; i < all_pickups.length; i++) {
      pickup = all_pickups[i];

      sameday = areSameDay(pickup.created_at, date);
      has_user = pickup.creator_id === user.id;

      if (sameday && has_user) {
        pickups.push(pickup);
      }
    }

    return pickups;
  }

  static hasWmOrUrDrive(user, date) {
    let pickups = Pickup.truckDriveForUserAndDate(user, date);

    let has_wm = pickups.find(p => p.school.short === 'wm');
    let has_ur = pickups.find(p => p.school.short === 'richmond');

    return [has_wm, has_ur];
  }

  static pickupsForUserAndDate(user,date) {
    let all_pickups = window.pickups;
    var pickup;
    var pickups = [];

    var i, sameday, has_user;

    for (i = 0; i < all_pickups.length; i++) {
      pickup = all_pickups[i];

      sameday = areSameDay(pickup.created_at, date);
      has_user = pickup.worker_ids.includes(user.id);

      if (sameday && has_user) {
        pickups.push(pickup);
      }
    }

    return pickups;
  }

  static pickupsForSchool(school) {
    let all_pickups = window.pickups;
    var pickup;
    var pickups = [];

    var i, has_user, belongs_to_school;

    for (i = 0; i < all_pickups.length; i++) {
      pickup = all_pickups[i];

      belongs_to_school = pickup.buildings[0].school_id === school.id;

      if (belongs_to_school) {
        pickups.push(pickup);
      }
    }

    return pickups;
  }

  static pickupsForUser(user) {
    let all_pickups = window.pickups;
    var pickup;
    var pickups = [];

    var i, has_user;

    for (i = 0; i < all_pickups.length; i++) {
      pickup = all_pickups[i];

      has_user = pickup.worker_ids.includes(user.id);

      if (has_user) {
        pickups.push(pickup);
      }
    }

    return pickups;
  }

  static totalCentsForUserForDate(user, date) {
    return sumBy(Pickup.pickupsForUserAndDate(user, date), p => p.individualPortionCents());
  }

  static totalCentsForUser(user) {
    return sumBy(Pickup.pickupsForUser(user), p => p.individualPortionCents());
  }

  static totalCentsForDeliveryForUserAndDate(user, date) {
    return sumBy(user.getAllDeliveriesForDate(date), p => p.driverCents());
  }

  static totalCentsForDeliveryForUser(user) {
    return sumBy(user.getAllDeliveries(), p => p.driverCents());
  }

  individualPortionCents() {
    let payscale = App.pickupPayscale;
    return this.totalCents(payscale) / this.worker_ids.length;
  }

  driverCents() {
    let payscale = App.deliveryPayscale;
    return this.totalCents(payscale);
  }

  assignData() {
    this.buildings = this.building_ids.map(bid => findById(window.buildings, bid));
    this.workers = this.worker_ids.map(wid => findById(window.workers, wid));
    this.creator = findById(window.workers, this.creator_id);
    this.school = findById(window.schools, this.school_id);
  }
}

export default Pickup;

import ApplicationObject from './application_object.js';
import uniqBy from 'lodash/uniqBy';
import flatten from 'lodash/flatten';
import compact from 'lodash/compact';
import sortBy from 'lodash/sortBy';
import concat from 'lodash/concat';
import { isPresent, assignFriendlyDate, findById } from '../util.js';

import {postData, patchData} from '../fetch_helpers.js';

const all_attrs = [
  'id',
  "building_id",
  "number",
  "wing",
  "locked",
  "occupied",
  "needs_attention",
  "needs_attention_text"
]

var working = false;

class Room extends ApplicationObject {
  wingName() {
    if (isPresent(this.wing)) {
      return `#${this.number}, ${this.wing}`;
    } else {
      return '#' + this.number;
    }
  }

  productShortNames() {
    return this.products.map(p => p.short)
  }

  realProductShortNames() {
    return this.installs.map(i => i.shortName())
  }

  hasAvailableJobs() {
    let available_install = this.installs.find(i => !i.purchased && !i.removed_by_id);
    return !!available_install;
  }

  getStatuses() {
    return this.installs.map(i => i.status);
  }

  hasStatus(status) {
    return !!this.installs.find(i => i.status === status);
  }

  rentedProductShortNames() {
    return this.rented_installs.map(i => i.product.short)
  }

  purchasedProductShortNames() {
    return this.purchased_installs.map(i => i.product.short)
  }

  showName() {
    return `${this.wingName()} - ${this.building.name}`
  }

  toJSON() {
    const json = {};

    all_attrs.forEach((a) => {
      json[a] = this[a];
    });

    return json;
  }

  rowName() {
    let building_name = this.building.name
    let name = `
      ${this.wingName()}: ${this.realProductShortNames().join(' ')}
    `

    return name;
  }

  setJson(data) {
    Object.entries(data).forEach(e => this[e[0]] = e[1]);
  }

  isBuilding() {
    return false;
  }

  getActivityData() {
    let install_activity = flatten(this.installs.map(i => i.getActivityData()));
    let room_activity = compact(flatten([this.getLockedActivity(), this.getOccupiedActivity()]));
    let ticket_activty = compact(flatten(this.tickets.map((t) => {
      let cell1 = {
        txt: `${t.creator.username} created a needs attention ticket on ${t.friendly_created_at}`,
        ts: t.created_at_ts
      }

      let cell2 = t.resolved_by ? {
        txt: `${t.resolved_by.username} resolved a ticket on ${t.friendly_resolved_at}`,
        ts: t.resolved_at_ts
      } : null;

      return [cell1, cell2];
    })));

    let all_activity = sortBy(concat(install_activity, room_activity, ticket_activty), a => a.ts);

    return all_activity;
  }

  getOccupiedActivity() {
    if (!this.set_occupied_by) return null;

    let activities = [{
      txt: `Set occupied on ${this.friendly_set_occupied_at} by ${this.set_occupied_by.username}`,
      ts: this.set_occupied_at_ts
    }];

    if (this.unoccupied_date_set_by_id) {
      let abs_diff = Math.abs(this.set_occupied_at_ts - this.unoccupied_date_set_at_ts);

      if (abs_diff > 120) {
        activities.push({
          txt: `Unoccupied date set on ${this.friendly_unoccupied_date_set_at} by ${this.unoccupied_date_set_by.username}`,
          ts: this.unoccupied_date_set_at_ts
        });
      }
    }

    return activities;
  }

  getLockedActivity() {
    if (!this.set_locked_by) return null;

    let status = this.locked ? 'door locked' : (this.locked === false ? 'door unlocked' : 'door status unknown');

    return {
      ts: this.set_locked_at_ts,
      txt: `${this.set_locked_by.username} set ${status} on ${this.friendly_set_locked_at}`
    }
  }

  static update(room_id, data, cb) {
    if (working) return;

    working = true

    let params = {room: data};

    patchData(`/rooms/${room_id}`, params)
      .catch(error => alert('an error occurred'))
      .then((json) => {
        if (json) {
          let room = window.rooms.find(r => r.id === room_id);
          room.resetData(json);
          room.assignData();

          if (cb) cb()

          App.roomView.forceUpdate()
        }

        working = false
      });
  }

  hasDoorStatus(status) {
    return (status === 'unknown' && this.locked === null) ||
           (status === 'unlocked' && this.locked === false) ||
           (status === 'locked' && this.locked === true)
  }

  unoccupiedTimeElapsed() {
    if (this.unoccupied_date_set_by_id && this.unoccupied_date_at_ts) {
      let current_ts = Math.floor((new Date()).getTime() / 1000)

      if (this.unoccupied_date_at_ts < current_ts) {
        return true;
      }
    }

    return false
  }

  assignInstallData() {
    this.purchased_installs = this.installs.filter(i => i.purchase);
    this.rented_installs = this.installs.filter(i => !i.purchase);
  }

  assignData() {
    this.building = findById(buildings, this.building_id);
    this.school = schools.find(s => s.id == this.building.school_id);
    this.installs = installs.filter(i => i.room_id == this.id);
    this.products = this.installs.map(i => i.product);

    if (this.set_locked_by_id) {
      this.set_locked_by = findById(window.workers, this.set_locked_by_id);
    } else {
      this.set_locked_by = null
      this.set_locked_at = null
      this.set_locked_at_ts = null
    }

    if (this.unoccupied_date_set_by_id) {
      this.unoccupied_date_set_by = findById(window.workers, this.unoccupied_date_set_by_id);
    } else {
      this.unoccupied_date_at = null
      this.unoccupied_date_set_by = null
      this.unoccupied_date_set_at = null
      this.unoccupied_date_set_at_ts = null
    }

    if (this.set_occupied_by_id) {
      this.set_occupied_by = findById(window.workers, this.set_occupied_by_id);

      if (this.unoccupiedTimeElapsed()) {
        this.occupied = false;
      } else {
        this.occupied = true;
      }
    } else {
      this.occupied = false;
      this.set_occupied_by = null
      this.set_occupied_at = null
      this.set_occupied_at_ts = null
    }

    this.unoccupied = !this.occupied;

    this.tickets = window.tickets.filter(t => t.room_id === this.id);

    let any_unresolved_tickets = this.tickets.find(t => !t.resolved_by_id);
    this.needs_attention = !!any_unresolved_tickets;

    this.assignInstallData();

    let students = this.installs.map(i => window.students.find(s => s.id == i.customer_id));
    this.students = uniqBy(students, s => s.id);

    this.field_notes = window.field_notes.filter(n => n.room_id === this.id);
  }
}

export default Room;

import React from 'react';
import {AppComponent} from '../app_components.js';
import {findById, isPresent} from '../util.js';

export default class BuildingView extends AppComponent {
  constructor(props) {
    super(props);
  }

  render() {
    let building = findById(window.buildings, this.props.buildingId);

    return (
      <div className='p-1'>
        <h5>{building.name}</h5>
        <p>{building.notes}</p>
        {isPresent(building.gmaps_link) &&
          <a target='_blank' href={building.gmaps_link}>{building.gmaps_link}</a>
        }
      </div>
    )
  }
}

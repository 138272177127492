import React from 'react';
import {AppComponent} from '../app_components';

import PayscalesTable from './payscales_table.jsx';

import map from 'lodash/map';
import compact from 'lodash/compact';
import sum from 'lodash/sum';
import bindAll from 'lodash/bindAll';

import strftime from 'strftime/strftime.js';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import Pickup from '../models/pickup.js';
import Stack from '../models/stack.js';
import ProductInstallation from '../models/product_installation.js';

import {collapseProds} from '../app_specific_utils.js';
import {millisecondsToHours, andJoin, centsToDollarString, shorterCentsToDollarString, findById} from '../util.js';
import {getStatsForUser, getMoneyForStats, getStatsForUserByDay} from '../stats_helper.js';

import {simpleActivityText} from '../activity_helpers.jsx';

var key1 = 99999;
export default class YourWorkView extends AppComponent {
  constructor(props) {
    super(props);

    if (props.workerId) {
      this.user = findById(window.workers, props.workerId);
      this.is_worker = true;
    } else {
      this.user = current_user;
      this.is_worker = false;
    }

    this.date = new Date();

    let dateValue = strftime('%Y-%m-%d', this.date);

    this.state = {
      dateValue: dateValue
    }

    bindAll(this, ['changedDate', 'renderActivity']);
  }

  renderStatsTable(prods) {
    let shorts = compact(map(prods, (v,k) => v.total > 0 ? k : null))

    return (
      <div key={key1+=1} className='mt-4'>
        <table className='muhtable'>
          <thead>
            <tr>
              <th></th>
              {shorts.map(s => (<th key={key1+=1}>{s}</th>))}
            </tr>
          </thead>
          <tbody>
            <tr className='uninstalled'>
              <td>uninstalled</td>
              {shorts.map(s => (<td key={key1+=1}>{prods[s][1]}</td>))}
            </tr>
            <tr className='removed'>
              <td>removed</td>
              {shorts.map(s => (<td key={key1+=1}>{prods[s][2]}</td>))}
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  renderMoneyTd(number) {
    let k = 'brah' + (key1+=1);
    return (
      <td key={k}>{centsToDollarString(number)}</td>
    )
  }

  changedDate(e) {
    let arr = e.target.value.split('-').map(v => parseInt(v));
    var [year, month, day] = arr;

    let d = new Date();

    d.setYear(year);
    d.setMonth(month - 1);
    d.setDate(day);

    this.date = d;
    this.setState({
      dateValue: e.target.value
    });
  }

  computeMoney(money, date) {
    let total = sum(map(money, nums => nums.total_amt));

    var delivery_total;
    if (date) {
      delivery_total = Pickup.totalCentsForDeliveryForUserAndDate(this.user, date) || 0;
    } else {
      delivery_total = Pickup.totalCentsForDeliveryForUser(this.user) || 0;
    }

    var pickup_total;
    if (date) {
      pickup_total = Pickup.totalCentsForUserForDate(this.user, date) || 0;
    } else {
      pickup_total = Pickup.totalCentsForUser(this.user) || 0;
    }

    var stack_total;
    if (date) {
      stack_total = Stack.totalCentsForUserForDate(this.user, date) || 0;
    } else {
      stack_total = Stack.totalCentsForUser(this.user) || 0;
    }

    let real_total = pickup_total + total + stack_total + delivery_total;

    return [total, delivery_total, pickup_total, stack_total, real_total, money];
  }

  renderMoneyTable(totals, prods, date) {
    const all_shorts = ProductInstallation.getCollapsedShorts();

    let [total, delivery_total, pickup_total, stack_total, real_total, money] = totals;

    let days_worked = this.user.getDaysWorked();

    return (
      <div key={key1+=1} className='mt-4'>
        { date ? (
          <div className='mb-2'>
            <div><span className='fw-500 mr-2'>Days worked:</span>{andJoin(days_worked)}</div>
            <div className='d-flex justify-content-between align-items-center'>
              <div>
                <div><span>{strftime('%b %e %Y', date)} earnings:</span></div>
              </div>

              <TextField
                id="date"
                label="Change date"
                onChange={this.changedDate}
                defaultValue={this.state.dateValue}
                type="date"
                InputLabelProps={{ shrink: true }}
              />
            </div>

            <table className='muhtable'>
              <thead>
                <tr>
                  <th>rooms</th>
                  {delivery_total > 0 &&
                    <th>truck driver</th>
                  }
                  <th>pickups</th>
                  <th>stacks</th>
                  <th>total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{centsToDollarString(total)}</td>
                  {delivery_total > 0 &&
                    <td>{centsToDollarString(delivery_total)}</td>
                  }
                  <td>{centsToDollarString(pickup_total)}</td>
                  <td>{centsToDollarString(stack_total)}</td>
                  <td>{centsToDollarString(real_total)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <div className='mb-2'>
            Total all-time earnings:
            <table className='muhtable'>
              <thead>
                <tr>
                  <th>rooms</th>
                  {delivery_total > 0 &&
                    <th>truck driver</th>
                  }
                  <th>pickups</th>
                  <th>stacks</th>
                  <th>total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{centsToDollarString(total)}</td>
                  {delivery_total > 0 &&
                    <td>{centsToDollarString(delivery_total)}</td>
                  }
                  <td>{centsToDollarString(pickup_total)}</td>
                  <td>{centsToDollarString(stack_total)}</td>
                  <td>{centsToDollarString(real_total)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

        <table className='muhtable'>
          <thead>
            <tr>
              <th></th>
              {all_shorts.map(s => (<th key={key1+=1}>{s}</th>))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              {all_shorts.map(s => {return this.renderMoneyTd(money[s] ? money[s].total_amt : 0)} )}
            </tr>
            <tr className='uninstalled'>
              <td></td>
              {all_shorts.map(s => {return this.renderMoneyTd(money[s] ? money[s].uninstall_amt : 0)} )}
            </tr>
            <tr className='removed'>
              <td></td>
              {all_shorts.map(s => {return this.renderMoneyTd(money[s] ? money[s].removal_amt : 0)} )}
            </tr>
            <tr className='uninstalled'>
              <td></td>
              {all_shorts.map(s => (<td key={key1+=1}>{prods[s] ? prods[s][1] : 0}</td>))}
            </tr>
            <tr className='removed'>
              <td></td>
              {all_shorts.map(s => (<td key={key1+=1}>{prods[s] ? prods[s][2] : 0}</td>))}
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  renderA(a) {
    let k = 'act_' + (key1+=1);

    if (a.type == 'pickup') {
      let time = strftime('on %-m/%-d/%y around %l:%M %p', a.date);
      let cnt = a.pickup.total();

      let buildings = andJoin(a.pickup.buildings.map(b => b.name));
      return (
        <div key={k} style={{fontSize: 14}}>
          Helped load {cnt} products on a truck for buildings {buildings} {time}
        </div>
      )
    } else if (a.type == 'stack') {
      let time = strftime('on %-m/%-d/%y around %l:%M %p', a.date);
      let cnt = a.stack.bl_count;

      return (
        <div key={k} style={{fontSize: 14}}>
          Helped stack {cnt} Bedlofts {time}
        </div>
      )
    } else  {
      let txt = a.type === 'removal' ? 'removed' : 'uninstalled';
      let i = a.install;
      let {product, building, room} = i;
      let short = product.short;
      let room_name = room.wingName();
      let building_name = building.name;
      let time = strftime('%l:%M %p', a.date);

      return (
        <div key={k} style={{fontSize: 14}}>
          {txt} {short} from {room_name} {building_name} @ {time}
        </div>
      )
    }
  }

  renderActivity(activity, date_totals) {
    let txt = this.is_worker ? `${this.user.username}'s` : 'Your';
    let date_txt = `${this.date.getMonth() + 1}/${this.date.getDate()}`;

    var first = activity[0];

    if (!first) return null;

    var last, last_time, hours_worked, hourly, extra_hours;

    let [total, delivery_total, pickup_total, stack_total, real_total, money] = date_totals;

    if (activity.length > 1) {
      last = activity[activity.length - 1];

      hours_worked = millisecondsToHours(last.date - first.date);
      extra_hours = 1;

      let [has_wm, has_ur] = Pickup.hasWmOrUrDrive(this.user, this.date);

      if (has_wm) extra_hours += 2;
      if (has_ur) extra_hours += 1;

      hourly = real_total / (hours_worked + extra_hours);
    }

    return (
      <div>
        <div className='mt-3 fw-500'>
          {txt} activity on {date_txt}:
        </div>
        { (first || last) && (
          <div>
            <Divider className='m-2' />

            {simpleActivityText(first, 'first')}
            {simpleActivityText(last, 'last')}
            {hours_worked &&
              <React.Fragment>
                <div>
                  <span className='fw-500 mr-2'>hours worked:</span>
                  {hours_worked} + {extra_hours} = {hours_worked + extra_hours}
                </div>
                <div>
                  <span className='fw-500 mr-2'>per hour:</span>
                  {centsToDollarString(real_total)} / {hours_worked + extra_hours} = {centsToDollarString(hourly)}
                </div>
              </React.Fragment>
            }

            <Divider className='m-2' />
          </div>
        )}

        {activity.map(this.renderA)}
      </div>
    )
  }

  render() {
    let prods = collapseProds(getStatsForUser(this.user));

    let money = getMoneyForStats(prods, payscales);

    let date_prods = collapseProds(getStatsForUserByDay(this.user, this.date));
    let date_money = getMoneyForStats(date_prods, payscales);

    let activity = this.user.getAllActivityForUserOnDate(this.date);

    var totals = this.computeMoney(money);
    var date_totals = this.computeMoney(date_money, this.date);

    let [total, delivery_total, pickup_total, stack_total, real_total] = totals;

    var total_hours = this.user.getTotalHoursWorked();
    let total_hourly = real_total / total_hours;

    return (
      <div className='p-2'>
        <PayscalesTable />

        {this.renderMoneyTable(totals, prods)}
        <div>
          <span className='fw-500 mr-2'>total hours worked:</span>
          {total_hours}
        </div>
        <div>
          <span className='fw-500 mr-2'>total hourly: </span>
          {centsToDollarString(total_hourly)}
        </div>
        {this.renderMoneyTable(date_totals, date_prods, this.date)}
        {this.renderActivity(activity, date_totals)}
      </div>
    );
  }
}

import ApplicationObject from './application_object.js';
import { isPresent } from '../util.js';

class Building extends ApplicationObject {
  schoolName() {
    return `${this.school.name} - ${this.name}`
  }

  fullName() {
    if (isPresent(this.complex)) {
      return `${this.name}-${this.complex}`;
    } else {
      return this.name;
    }
  }

  isBuilding() {
    return true;
  }

  assignData() {
    this.school = schools.find(s => s.id == this.school_id);
  }
}

export default Building;

import React from 'react';

export class AppComponent extends React.Component {
  constructor(props) {
    super(props);

    App.currentView = this;
  }

  componentWillUnmount() {
    App.currentView = null;
  }
}

export class PureAppComponent extends React.PureComponent {
  constructor(props) {
    super(props);

    App.currentView = this;
  }

  componentWillUnmount() {
    App.currentView = null;
  }
}

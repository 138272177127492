import ApplicationObject from './application_object.js';
import reject from 'lodash/reject';
import sumBy from 'lodash/sumBy';
import { getFilteredRooms, getFilterText, getFilterState, getViewState } from '../helpers/filter_helpers.js';
import { attemptParseInt } from '../util.js';

// clone deez
const new_filter = {
// applied: false,
  buildings: [],
  products: [],
  schools: [],
  product_statuses: [],
  showAvailableJobs: true,
  room_statuses: [],
  door_statuses: [],
  areas: []
}

const new_view_filter = {
//  applied: false,
  building_ids: [],
  product_ids: [],
  school_ids: [],
  product_statuses: [],
  showAvailableJobs: true,
  room_statuses: [],
  door_statuses: [],
  areas: []
}

class Filter extends ApplicationObject {
  constructor(props) {
    super(props)

    this.product_statuses = this.product_statuses.map(s => parseInt(s));
  }

  static saveItems() {
    let filters = App.filters.map(f => getViewState(f));
    localStorage.setItem('filters', JSON.stringify(filters));
  }

  static getCalcEle() {
    if (this.height_calc) return this.height_cal;

    return (this.height_cal = document.getElementById('height_calc'));
  }

  static loadFromLocalStorage() {
    let filters_json = JSON.parse(localStorage.getItem('filters'));
    if (filters_json) {
      return filters_json.map(j => new Filter(getFilterState(j)))
    } else {
      return null;
    }
  }

  hasSpots() {
    return (this.buildings.length || this.areas.length);
  }

  static clearAll() {
    localStorage.setItem('filters', null);
    App.filters = [];
  }

  static pushItem(filter) {
    filter.id = Filter.getNextId()
    App.filters.forEach(f => f.applied = false);
    App.filters.push(filter);
    Filter.saveItems();
  }

  static getNextId() {
    let last_id = attemptParseInt(localStorage.getItem('last_filter_id')) || 0;
    last_id += 1;

    localStorage.setItem('last_filter_id', last_id);

    return last_id;
  }

  static deleteFilter(filter) {
    App.filters = reject(App.filters, f => f.id === filter.id);
    Filter.saveItems();
  }

  static unapply() {
    App.filters.forEach(f => f.applied = false);
    Filter.saveItems();
  }

  static getCurrentFilter() {
    return App.filters.find(f => f.applied);
  }

  static getViewFilterStateOrNew() {
    let filter = Filter.getCurrentFilter();

    return filter ? filter.getViewState() : Filter.getNewViewFilterState();
  }

  static getNewViewFilterState() {
    return Object.assign({}, new_view_filter);
  }

  getProductCounts(rooms) {
    var cnt, prods_match;
    var cnt_hash = {};
    let statuses = this.product_statuses;

    this.products.forEach((product) => {
      cnt = sumBy(rooms, (room) => {
        return room.installs.filter((install) => {
          prods_match = product.id === install.product.id;
          if (statuses.length) {
            return prods_match && statuses.includes(install.status);
          } else {
            return prods_match;
          }
        }).length
      });

      cnt_hash[product.short] = cnt
    });

    return cnt_hash;
  }

  hasLocation() {
    return !!(this.schools.length || this.buildings.length || this.areas.length);
  }

  toggleApply() {
    let applied = this.applied
    App.filters.forEach(f => f.applied = false);
    this.applied = !applied;
    Filter.saveItems();
  }

  getViewState() {
    return getViewState(this);
  }

  getListText(rooms) {
    let txt = `Showing ${rooms.length}`;
    let cnts = this.getProductCounts(rooms);
    txt = getFilterText(txt, this, cnts);

    return txt
  }

  getFilterText() {
    return getFilterText('Filter by', this);
  }

  getRooms() {
    return getFilteredRooms(this);
  }
}


export default Filter;

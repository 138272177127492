import React from 'react';
import {AppComponent} from '../app_components';

import bindAll from 'lodash/bindAll';
import map from 'lodash/map';
import flatten from 'lodash/flatten';
import compact from 'lodash/compact';
import sum from 'lodash/sum';
import pick from 'lodash/pick';
import sumBy from 'lodash/sumBy';
import toUpper from 'lodash/toUpper';

import classnames from 'classnames';

import {andJoin} from '../util.js';
import {getAllStats, getStatsForFilter, calcPickups} from '../stats_helper.js';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Pickup from '../models/pickup.js';
import ProductInstallation from '../models/product_installation.js';

var key1 = 99;
export default class FilterStatsView extends AppComponent  {
  constructor(props) {
    super(props);

    bindAll(this, ['renderSchoolTable', 'toggledFilterOccupied', 'renderCheckbox']);

    this.state = {
      filter_occupied: false
    }
  }

  renderProduct(val, key, school_id) {
    var short, status;
    [short, status] = key.split('_');

    let klass = `text-${ProductInstallation.getColorFromStatusName(status)}`;
    let txt = `${short} (${val})`;

    let ract_key = `${key}-${school_id}`;

    return (
      <span key={ract_key} className={klass}>{txt}</span>
    )
  }

  renderRow(status, totals, school) {
    let shorts = window.products.map(p => p.short);

    let klass = classnames('ml-1', {
      'text-danger': status == 'installed',
      'text-warning': status == 'uninstalled',
      'text-info': status == 'removed',
    });

    return (
      <div key={key1 += 1}>
        <b>{status}:</b>
        {shorts.map((short) => {
          let num = totals.find(t => t.short == short)[status];

          return num > 0 ? (
            <span className={klass} key={key1+=1}>{short} ({num})</span>
          ) : null;
        })}
      </div>
    )
  }

  renderPickupsTd(short, stats) {
    var colspan = 1;
    if (short === 'tv' || short === 'ft') {
      colspan = 2;
    }

    return (
      <td colSpan={colspan} key={key1+=1}>{stats[short]}</td>
    )
  }

  renderPickupCalcTd(short, pickup_stats, stats, attr) {
    var colspan = 1;
    var stats_total;
    if (short === 'tv') {
      colspan = 2;
      stats_total = stats.prods['TV32'][attr] + stats.prods['TV40'][attr];
    } else if (short === 'ft') {
      colspan = 2;
      stats_total = stats.prods['FT'][attr] + stats.prods['DFT'][attr];
    } else {
      stats_total = stats.prods[toUpper(short)][attr];
    }

    let pickup_total = pickup_stats[short];
    let num = stats_total - pickup_total;

    return (
      <td colSpan={colspan} key={key1+=1}>
        {short === 'tv' || short === 'ft' ?
          (<span>{stats_total}-{pickup_total}={num}</span>)
          :
          (<span>{num}</span>)
        }
      </td>
    )
  }

  renderSchoolTable(stats, show_pickups) {
    let shorts = ProductInstallation.getShorts();

    if (show_pickups) {
      var pickups = Pickup.pickupsForSchool(stats.school);
      var pickup_stats = calcPickups(pickups);
    }

    return (
      <div key={key1+=1} className='mt-2'>
        <div>{stats.school.name} stats</div>
        <table className='muhtable'>
          <thead>
            <tr>
              {shorts.map(s => (<th key={key1+=1}>{s}</th>))}
            </tr>
          </thead>
          <tbody>
            <tr className='total'>
              {shorts.map(s => (<td key={key1+=1}>{stats.prods[s].total}</td>))}
            </tr>
            <tr className='installed'>
              {shorts.map(s => (<td key={key1+=1}>{stats.prods[s][0]}</td>))}
            </tr>
            <tr className='uninstalled'>
              {shorts.map(s => (<td key={key1+=1}>{stats.prods[s][1]}</td>))}
            </tr>
            <tr className='removed'>
              {shorts.map(s => (<td key={key1+=1}>{stats.prods[s][2]}</td>))}
            </tr>

            { show_pickups &&
              <React.Fragment>
                <tr className='total text-secondary' style={{borderTop: '1px solid #6c757d'}}>
                  {Pickup.getShorts().map(s => this.renderPickupsTd(s,pickup_stats))}
                </tr>
                <tr className='total'>
                  {Pickup.getShorts().map(s => this.renderPickupCalcTd(s, pickup_stats, stats, 'total'))}
                </tr>
              </React.Fragment>
            }

          </tbody>
        </table>
      </div>
    )
  }

  renderFilter(filter, stats) {
    var {buildings, schools, areas, showAvailableJobs, room_statuses, door_statuses, products, product_statuses} = filter;

    let totals = map(stats, (school_stat) => {
      return map(school_stat.prods, p => p.total);
    })

    let total = sum(flatten(totals));

    let txt = filter.showAvailableJobs ? 'available ' : '';

    return (
      <div>
        <h5>Room Filter Criteria:</h5>
        <div className='mt-2'>
          <b>available or all jobs:</b>
          {showAvailableJobs ? ' available' : ' all'}
        </div>

        { room_statuses.length > 0 &&
          <div className='mt-2'>
            <b>room statuses:</b>
            {andJoin(room_statuses.map(s => s), 'OR')}
          </div>
        }

        { door_statuses.length > 0 &&
          <div className='mt-2'>
            <b>door statuses:</b>
            {andJoin(door_statuses.map(s => s), 'OR')}
          </div>
        }

        { schools.length > 0 &&
          <div>
            <div>AND</div>
            <b>schools:</b>
            {andJoin(schools.map(b => b.name), 'OR')}
          </div>
        }

        { areas.length > 0 &&
          <div>
            <div>AND</div>
            <b>areas:</b>
            {andJoin(areas.map(a => a), 'OR')}
          </div>
        }
        { buildings.length > 0 &&
          <div>
            <div>AND</div>
            <b>buildings:</b>
            {andJoin(buildings.map(b => b.name), 'OR')}
          </div>
        }
        {products.length > 0 &&
          <div>
            <div>AND</div>
            <b>products:</b>
            {andJoin(products.map(p => p.short), 'OR')}
          </div>
        }

        { product_statuses.length > 0 &&
          <div>
            <div>AND</div>
            <b>product statuses:</b>
            {andJoin(product_statuses.map(s => ProductInstallation.getNameFromNumber(s)), 'OR')}
          </div>
        }
      </div>
    )
  }

  renderStatsCriteria(filter) {
    let {schools, buildings, areas} = filter;

    return (
      <div>
        <h5>Stats Filter Criteria:</h5>

        <div><b>all jobs</b></div>
        { schools.length > 0 &&
          <div>
            <div>AND</div>
            <b>schools: </b>
            {andJoin(schools.map(b => b.name), 'OR')}
          </div>
        }
        { areas.length > 0 &&
          <div>
            <div>AND</div>
            <b>areas: </b>
            {andJoin(areas.map(a => a), 'OR')}
          </div>
        }
        { buildings.length > 0 &&
          <div>
            <div>AND</div>
            <b>buildings: </b>
            {andJoin(buildings.map(b => b.name), 'OR')}
          </div>
        }
      </div>
    )
  }

  toggledFilterOccupied(e) {
    this.setState({filter_occupied: event.target.checked});
  }

  renderCheckbox() {
    return (
      <div className='mt-3'>
        <FormControlLabel
          control={
            <Checkbox
              checked={this.state.filter_occupied}
              onChange={this.toggledFilterOccupied}
              value="filterOccupied"
              color="primary"
            />
          }
          label="Filter out occupied rooms from stats below"
        />
      </div>
    )
  }

  render() {
    let filter = Filter.getCurrentFilter();

    var show_pickups = false;

    let filter_occupied = this.state.filter_occupied;

    if (filter) {
      let stats = getStatsForFilter(filter, filter_occupied);

      if (!filter.hasSpots()) {
        show_pickups = true
      }

      return (
        <div className='p-1' style={{fontSize: 15}}>
          {this.renderFilter(filter, stats)}
          <div className='mt-3'>
            {filter.hasLocation() &&
              this.renderStatsCriteria(filter)
            }

            {this.renderCheckbox()}
            {stats.map(s => this.renderSchoolTable(s, show_pickups))}
          </div>
        </div>
      )
    } else {
      let all_stats = getAllStats(filter_occupied);

      show_pickups = true;
      return (
        <div style={{fontSize: 15}}>
          {this.renderCheckbox()}

          {all_stats.map(s => this.renderSchoolTable(s, show_pickups))}
        </div>
      )
    }

  }
}
